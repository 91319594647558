!function ($) {
    'use strict';

    $(document).ready(function () {
        $('.dropdown-toggle').click(function (e) {
            e.preventDefault();
            setTimeout($.proxy(function () {
                if ('ontouchstart' in document.documentElement) {
                    $(this).siblings('.dropdown-backdrop').off().remove();
                }
            }, this), 0);
        });

        if ($('.siqual-image-hover').length) {
            $('.siqual-image-hover article').hover(function () {
                var $this = $(this);
                var fromTop = ($('.siqual-image-wrapper', $this).height() / 2 - $('.siqual-image-icon-links', $this).height() / 2);
                $('.siqual-image-icon-links', $this).css('margin-top', fromTop);
                $('.siqual-image-media-hover', $this).height($('.siqual-image-wrapper', $this).height());
                $('.siqual-image-mask', this).css('height', $('.siqual-image-wrapper', this).height());
                $('.siqual-image-mask', this).css('width', $('.siqual-image-wrapper', this).width());
                $('.siqual-image-mask', this).css('margin-top', $('.siqual-image-wrapper', this).height());

                $('.siqual-image-mask', this).stop(1).show().css('margin-top', $('.siqual-image-wrapper', this).height()).animate({marginTop: 0}, 200, function () {
                    $('.siqual-image-icon-links', $this).css('display', 'block');
                    if (Modernizr.csstransitions) {
                        $('.siqual-image-icon-links a').addClass('animated');
                        $('.siqual-image-icon-links a', $this).removeClass('flipOutX');
                        $('.siqual-image-icon-links a', $this).addClass('bounceInDown');
                    } else {
                        $('.siqual-image-icon-links', $this).stop(true, false).fadeIn('fast');
                    }
                });
            }, function () {
                var $this = $(this);

                $('.siqual-image-mask', this).stop(1).show().animate({marginTop: $('.siqual-image-wrapper', $this).height()}, 200, function () {
                    if (Modernizr.csstransitions) {
                        $('.siqual-image-icon-links a', $this).removeClass('bounceInDown');
                        $('.siqual-image-icon-links a', $this).addClass('flipOutX');
                    } else {
                        $('.siqual-image-icon-links', $this).stop(true, false).fadeOut('fast');
                    }
                });
            });
        }

        $(document).delegate('.select2-selection__clear', 'click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            var $select2 = $(this).closest('.select2');
            $select2.prev().val('').trigger('change');
        });

        $(document).delegate('.select2-hidden-accessible', 'select2:unselect', function(e) {
            var $this = $(this);
            setTimeout(function() {
                $this.parent().find('.select2-search__field').val('');
            }, 100);
            setTimeout(function() {
                $this.parent().find('.select2-search__field').val('').focus();
            }, 200);
        });

        $(document).delegate('.select2-hidden-accessible', 'select2:select', function(e) {
            var $this = $(this);
            // $this.select2('open'); // ouvrir le dropdown
            setTimeout(function() {
                $this.parent().find('.select2-search__field').focus();
            }, 200);
        });
    });
}(jQuery);