!function ( $ ) {
    'use strict';

    $(document).ready(function() {
        $(document).delegate('.siqual-spinner', 'click', function() {
            $(this).find('i').data('class', $(this).find('i').attr('class'));
            $(this).find('i').attr('class', 'icon-spinner icon-spin');
        });
        $(document).delegate('#siqual_admin_form_save', 'click', function() {
            $(this).find('i').data('class', $(this).find('i').attr('class'));
            $(this).find('i').attr('class', 'icon-spinner icon-spin');
        });
        $(document).delegate('#siqual_admin_form_save_and_add', 'click', function() {
            $(this).find('i').data('class', $(this).find('i').attr('class'));
            $(this).find('i').attr('class', 'icon-spinner icon-spin');
        });
        $(document).delegate('#siqual_admin_form_save_and_list', 'click', function() {
            $(this).find('i').data('class', $(this).find('i').attr('class'));
            $(this).find('i').attr('class', 'icon-spinner icon-spin');
        });
    });
}( jQuery );