(function (t) {
// fr
t.add("This value should be false.", "Cette valeur doit \u00eatre fausse.", "validators", "fr");
t.add("This value should be true.", "Cette valeur doit \u00eatre vraie.", "validators", "fr");
t.add("This value should be of type {{ type }}.", "Cette valeur doit \u00eatre de type {{ type }}.", "validators", "fr");
t.add("This value should be blank.", "Cette valeur doit \u00eatre vide.", "validators", "fr");
t.add("The value you selected is not a valid choice.", "Cette valeur doit \u00eatre l'un des choix propos\u00e9s.", "validators", "fr");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Vous devez s\u00e9lectionner au moins {{ limit }} choix.|Vous devez s\u00e9lectionner au moins {{ limit }} choix.", "validators", "fr");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Vous devez s\u00e9lectionner au maximum {{ limit }} choix.|Vous devez s\u00e9lectionner au maximum {{ limit }} choix.", "validators", "fr");
t.add("One or more of the given values is invalid.", "Une ou plusieurs des valeurs soumises sont invalides.", "validators", "fr");
t.add("This field was not expected.", "Ce champ n'a pas \u00e9t\u00e9 pr\u00e9vu.", "validators", "fr");
t.add("This field is missing.", "Ce champ est manquant.", "validators", "fr");
t.add("This value is not a valid date.", "Cette valeur n'est pas une date valide.", "validators", "fr");
t.add("This value is not a valid datetime.", "Cette valeur n'est pas une date valide.", "validators", "fr");
t.add("This value is not a valid email address.", "Cette valeur n'est pas une adresse email valide.", "validators", "fr");
t.add("The file could not be found.", "Le fichier n'a pas \u00e9t\u00e9 trouv\u00e9.", "validators", "fr");
t.add("The file is not readable.", "Le fichier n'est pas lisible.", "validators", "fr");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Le fichier est trop volumineux ({{ size }} {{ suffix }}). Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.", "validators", "fr");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Le type du fichier est invalide ({{ type }}). Les types autoris\u00e9s sont {{ types }}.", "validators", "fr");
t.add("This value should be {{ limit }} or less.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.", "validators", "fr");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("This value should be {{ limit }} or more.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.", "validators", "fr");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("This value should not be blank.", "Cette valeur ne doit pas \u00eatre vide.", "validators", "fr");
t.add("This value should not be null.", "Cette valeur ne doit pas \u00eatre nulle.", "validators", "fr");
t.add("This value should be null.", "Cette valeur doit \u00eatre nulle.", "validators", "fr");
t.add("This value is not valid.", "Cette valeur n'est pas valide.", "validators", "fr");
t.add("This value is not a valid time.", "Cette valeur n'est pas une heure valide.", "validators", "fr");
t.add("This value is not a valid URL.", "Cette valeur n'est pas une URL valide.", "validators", "fr");
t.add("The two values should be equal.", "Les deux valeurs doivent \u00eatre identiques.", "validators", "fr");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Le fichier est trop volumineux. Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.", "validators", "fr");
t.add("The file is too large.", "Le fichier est trop volumineux.", "validators", "fr");
t.add("The file could not be uploaded.", "Le t\u00e9l\u00e9chargement de ce fichier est impossible.", "validators", "fr");
t.add("This value should be a valid number.", "Cette valeur doit \u00eatre un nombre.", "validators", "fr");
t.add("This file is not a valid image.", "Ce fichier n'est pas une image valide.", "validators", "fr");
t.add("This is not a valid IP address.", "Cette adresse IP n'est pas valide.", "validators", "fr");
t.add("This value is not a valid language.", "Cette langue n'est pas valide.", "validators", "fr");
t.add("This value is not a valid locale.", "Ce param\u00e8tre r\u00e9gional n'est pas valide.", "validators", "fr");
t.add("This value is not a valid country.", "Ce pays n'est pas valide.", "validators", "fr");
t.add("This value is already used.", "Cette valeur est d\u00e9j\u00e0 utilis\u00e9e.", "validators", "fr");
t.add("The size of the image could not be detected.", "La taille de l'image n'a pas pu \u00eatre d\u00e9tect\u00e9e.", "validators", "fr");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "La largeur de l'image est trop grande ({{ width }}px). La largeur maximale autoris\u00e9e est de {{ max_width }}px.", "validators", "fr");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "La largeur de l'image est trop petite ({{ width }}px). La largeur minimale attendue est de {{ min_width }}px.", "validators", "fr");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "La hauteur de l'image est trop grande ({{ height }}px). La hauteur maximale autoris\u00e9e est de {{ max_height }}px.", "validators", "fr");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "La hauteur de l'image est trop petite ({{ height }}px). La hauteur minimale attendue est de {{ min_height }}px.", "validators", "fr");
t.add("This value should be the user's current password.", "Cette valeur doit \u00eatre le mot de passe actuel de l'utilisateur.", "validators", "fr");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8re.|Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("The file was only partially uploaded.", "Le fichier a \u00e9t\u00e9 partiellement transf\u00e9r\u00e9.", "validators", "fr");
t.add("No file was uploaded.", "Aucun fichier n'a \u00e9t\u00e9 transf\u00e9r\u00e9.", "validators", "fr");
t.add("No temporary folder was configured in php.ini.", "Aucun r\u00e9pertoire temporaire n'a \u00e9t\u00e9 configur\u00e9 dans le php.ini.", "validators", "fr");
t.add("Cannot write temporary file to disk.", "Impossible d'\u00e9crire le fichier temporaire sur le disque.", "validators", "fr");
t.add("A PHP extension caused the upload to fail.", "Une extension PHP a emp\u00each\u00e9 le transfert du fichier.", "validators", "fr");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou plus.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou plus.", "validators", "fr");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou moins.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou moins.", "validators", "fr");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ment.|Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ments.", "validators", "fr");
t.add("Invalid card number.", "Num\u00e9ro de carte invalide.", "validators", "fr");
t.add("Unsupported card type or invalid card number.", "Type de carte non support\u00e9 ou num\u00e9ro invalide.", "validators", "fr");
t.add("This is not a valid International Bank Account Number (IBAN).", "Le num\u00e9ro IBAN (International Bank Account Number) saisi n'est pas valide.", "validators", "fr");
t.add("This value is not a valid ISBN-10.", "Cette valeur n'est pas un code ISBN-10 valide.", "validators", "fr");
t.add("This value is not a valid ISBN-13.", "Cette valeur n'est pas un code ISBN-13 valide.", "validators", "fr");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Cette valeur n'est ni un code ISBN-10, ni un code ISBN-13 valide.", "validators", "fr");
t.add("This value is not a valid ISSN.", "Cette valeur n'est pas un code ISSN valide.", "validators", "fr");
t.add("This value is not a valid currency.", "Cette valeur n'est pas une devise valide.", "validators", "fr");
t.add("This value should be equal to {{ compared_value }}.", "Cette valeur doit \u00eatre \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be greater than {{ compared_value }}.", "Cette valeur doit \u00eatre sup\u00e9rieure \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Cette valeur doit \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.", "validators", "fr");
t.add("This value should be less than {{ compared_value }}.", "Cette valeur doit \u00eatre inf\u00e9rieure \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be less than or equal to {{ compared_value }}.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should not be equal to {{ compared_value }}.", "Cette valeur ne doit pas \u00eatre \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Cette valeur ne doit pas \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.", "validators", "fr");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Le rapport largeur\/hauteur de l'image est trop grand ({{ ratio }}). Le rapport maximal autoris\u00e9 est {{ max_ratio }}.", "validators", "fr");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Le rapport largeur\/hauteur de l'image est trop petit ({{ ratio }}). Le rapport minimal attendu est {{ min_ratio }}.", "validators", "fr");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "L'image est carr\u00e9e ({{ width }}x{{ height }}px). Les images carr\u00e9es ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "L'image est au format paysage ({{ width }}x{{ height }}px). Les images au format paysage ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "L'image est au format portrait ({{ width }}x{{ height }}px). Les images au format portrait ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("An empty file is not allowed.", "Un fichier vide n'est pas autoris\u00e9.", "validators", "fr");
t.add("The host could not be resolved.", "Le nom de domaine n'a pas pu \u00eatre r\u00e9solu.", "validators", "fr");
t.add("This value does not match the expected {{ charset }} charset.", "Cette valeur ne correspond pas au jeu de caract\u00e8res {{ charset }} attendu.", "validators", "fr");
t.add("This is not a valid Business Identifier Code (BIC).", "Ce n'est pas un code universel d'identification des banques (BIC) valide.", "validators", "fr");
t.add("Error", "Erreur", "validators", "fr");
t.add("This is not a valid UUID.", "Ceci n'est pas un UUID valide.", "validators", "fr");
t.add("This value should be a multiple of {{ compared_value }}.", "Cette valeur doit \u00eatre un multiple de {{ compared_value }}.", "validators", "fr");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Ce code d'identification d'entreprise (BIC) n'est pas associ\u00e9 \u00e0 l'IBAN {{ iban }}.", "validators", "fr");
t.add("This value should be valid JSON.", "Cette valeur doit \u00eatre un JSON valide.", "validators", "fr");
t.add("This collection should contain only unique elements.", "Cette collection ne doit pas comporter de doublons.", "validators", "fr");
t.add("This value should be positive.", "Cette valeur doit \u00eatre strictement positive.", "validators", "fr");
t.add("This value should be either positive or zero.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.", "validators", "fr");
t.add("This value should be negative.", "Cette valeur doit \u00eatre strictement n\u00e9gative.", "validators", "fr");
t.add("This value should be either negative or zero.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.", "validators", "fr");
t.add("This value is not a valid timezone.", "Cette valeur n'est pas un fuseau horaire valide.", "validators", "fr");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Ce mot de passe a \u00e9t\u00e9 divulgu\u00e9 lors d'une fuite de donn\u00e9es, il ne doit plus \u00eatre utilis\u00e9. Veuillez utiliser un autre mot de passe.", "validators", "fr");
t.add("This value should be between {{ min }} and {{ max }}.", "Cette valeur doit \u00eatre comprise entre {{ min }} et {{ max }}.", "validators", "fr");
t.add("This form should not contain extra fields.", "Ce formulaire ne doit pas contenir des champs suppl\u00e9mentaires.", "validators", "fr");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Le fichier t\u00e9l\u00e9charg\u00e9 est trop volumineux. Merci d'essayer d'envoyer un fichier plus petit.", "validators", "fr");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Le jeton CSRF est invalide. Veuillez renvoyer le formulaire.", "validators", "fr");
t.add("siqual.common.validators.blank.message", "Ce champ doit \u00eatre vide", "validators", "fr");
t.add("siqual.common.validators.choice.maxMessage", "Vous devez s\u00e9lectionner au maximum {{ limit }} valeurs", "validators", "fr");
t.add("siqual.common.validators.choice.message", "La valeur s\u00e9lectionn\u00e9e n'est pas valide", "validators", "fr");
t.add("siqual.common.validators.choice.minMessage", "Vous devez s\u00e9lectionner au moins {{ limit }} valeurs", "validators", "fr");
t.add("siqual.common.validators.choice.multipleMessage", "Une ou plusieurs des valeurs s\u00e9lectionn\u00e9es n'est pas valide", "validators", "fr");
t.add("siqual.common.validators.collection.extraFieldsMessage", "Les champs {{ fields }} n'\u00e9taient pas pr\u00e9vus", "validators", "fr");
t.add("siqual.common.validators.collection.missingFieldsMessage", "Les champs {{ fields }} sont manquants", "validators", "fr");
t.add("siqual.common.validators.count.exactMessage", "Il doit y a voir exactement {{ limit }} entr\u00e9es", "validators", "fr");
t.add("siqual.common.validators.count.maxMessage", "Un maximum de {{ limit }} est permis", "validators", "fr");
t.add("siqual.common.validators.count.minMessage", "Un minimum de {{ limit }} est requis", "validators", "fr");
t.add("siqual.common.validators.country.message", "Cette valeur n'est pas un pays valide", "validators", "fr");
t.add("siqual.common.validators.date.message", "Cette valeur n'est pas une date valide", "validators", "fr");
t.add("siqual.common.validators.dateTime.message", "Cette valeur n'est pas un dateTime valide", "validators", "fr");
t.add("siqual.common.validators.email.message", "Merci d'entrer une adresse email valide", "validators", "fr");
t.add("siqual.common.validators.empty.message", "Merci de remplir ce champ", "validators", "fr");
t.add("siqual.common.validators.equalTo.message", "La valeur doit \u00eatre \u00e9gale \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.file.disallowEmptyMessage", "Un fichier vide n'est pas autoris\u00e9", "validators", "fr");
t.add("siqual.common.validators.file.maxSizeMessage", "Le fichier est trop large ({{ size }} {{ suffix }}). Taille maximum autoris\u00e9e : {{ limit }} {{ suffix }}", "validators", "fr");
t.add("siqual.common.validators.file.mimeTypesMessage", "Le type MIME du fichier est invalide ({{ type }}). MIME types autoris\u00e9s : {{ types }}", "validators", "fr");
t.add("siqual.common.validators.file.notFoundMessage", "Fichier non trouv\u00e9", "validators", "fr");
t.add("siqual.common.validators.file.notReadableMessage", "Fichier non lisible", "validators", "fr");
t.add("siqual.common.validators.file.uploadFormSizeErrorMessage", "Le fichier est trop lourd", "validators", "fr");
t.add("siqual.common.validators.file.uploadIniSizeErrorMessage", "Le fichier est trop lourd. Taille maximum autoris\u00e9e : {{ limit }} {{ suffix }}", "validators", "fr");
t.add("siqual.common.validators.file.uploadErrorMessage", "Le fichier n'a pas pu \u00eatre upload\u00e9", "validators", "fr");
t.add("siqual.common.validators.greaterThan.message", "La valeur doit \u00eatre sup\u00e9rieur \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.greaterThanOrEqual.message", "La valeur doit \u00eatre sup\u00e9rieur ou \u00e9gale \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.identicalTo.message", "La valeur doit \u00eatre strictement \u00e9gale \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.image.allowLandscapeMessage", "L'image est orient\u00e9e en paysage ({{ width }}x{{ height }}px). Les images orient\u00e9es en paysage ne sont pas autoris\u00e9es", "validators", "fr");
t.add("siqual.common.validators.image.allowPortraitMessage", "L'image est orient\u00e9e en portrait ({{ width }}x{{ height }}px). Les images orient\u00e9es en portrait ne sont pas autoris\u00e9es", "validators", "fr");
t.add("siqual.common.validators.image.allowSquareMessage", "L'image est carr\u00e9 ({{ width }}x{{ height }}px). Les images carr\u00e9es ne sont pas autoris\u00e9es", "validators", "fr");
t.add("siqual.common.validators.image.corruptedMessage", "L'image est corrompue", "validators", "fr");
t.add("siqual.common.validators.image.maxHeightMessage", "La hauteur de l'image est trop grande ({{ height }}px). La hauteur maximale autoris\u00e9e est {{ max_height }}px", "validators", "fr");
t.add("siqual.common.validators.image.maxRatioMessage", "Le ratio de l'image est trop grand ({{ ratio }}). Le ratio maximum autoris\u00e9 est {{ max_ratio }}", "validators", "fr");
t.add("siqual.common.validators.image.maxWidthMessage", "La largeur de l'image est trop grande ({{ width }}px). La largeur maximale autoris\u00e9e est {{ max_width }}px", "validators", "fr");
t.add("siqual.common.validators.image.mimeTypesMessage", "Ce fichier n'est pas une image valide", "validators", "fr");
t.add("siqual.common.validators.image.minHeightMessage", "La hauteur de l'image est trop petite ({{ height }}px). La hauteur minimale autoris\u00e9e est {{ min_height }}px", "validators", "fr");
t.add("siqual.common.validators.image.minRatioMessage", "le ratio de l'image est trop petit ({{ ratio }}). Le ratio minimum autoris\u00e9 est {{ min_ratio }}", "validators", "fr");
t.add("siqual.common.validators.image.minWidthMessage", "La largeur de l'image est trop petite ({{ width }}px). La largeur minimale autoris\u00e9e est {{ min_width }}px", "validators", "fr");
t.add("siqual.common.validators.image.sizeNotDetectedMessage", "La taille de l'image n'a pas pu \u00eatre d\u00e9tect\u00e9e", "validators", "fr");
t.add("siqual.common.validators.ip.message", "Cette IP n'est pas valide", "validators", "fr");
t.add("siqual.common.validators.isFalse.message", "Ce champ doit \u00eatre faux", "validators", "fr");
t.add("siqual.common.validators.isNull.message", "Ce champ doit \u00eatre vide", "validators", "fr");
t.add("siqual.common.validators.isTrue.message", "Ce champ doit \u00eatre vrai", "validators", "fr");
t.add("siqual.common.validators.isUniqueEmail.message", "Cet email est d\u00e9j\u00e0 utilis\u00e9, veuillez en choisir un autre", "validators", "fr");
t.add("siqual.common.validators.language.message", "Cette valeur n'est pas un langage valide", "validators", "fr");
t.add("siqual.common.validators.length.exactMessage", "La valeur doit faire exactement {{ limit }} caract\u00e8res", "validators", "fr");
t.add("siqual.common.validators.length.maxMessage", "La valeur ne doit pas d\u00e9passer {{ limit }} caract\u00e8res", "validators", "fr");
t.add("siqual.common.validators.length.minMessage", "La valeur doit faire au moins {{ limit }} caract\u00e8res", "validators", "fr");
t.add("siqual.common.validators.lessThan.message", "La valeur doit \u00eatre inf\u00e9rieure \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.lessThanOrEqual.message", "La valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.locale.message", "Cette valeur n'est pas une locale valide", "validators", "fr");
t.add("siqual.common.validators.notBlank.message", "Ce champ ne doit pas \u00eatre vide", "validators", "fr");
t.add("siqual.common.validators.notEqualTo.message", "La valeur doit \u00eatre diff\u00e9rente de {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.notIdenticalTo.message", "La valeur doit \u00eatre strictement diff\u00e9rente de {{ compared_value }}", "validators", "fr");
t.add("siqual.common.validators.notNull.message", "Ce champ ne doit pas \u00eatre vide", "validators", "fr");
t.add("siqual.common.validators.range.invalidMessage", "Cette valeur n'est pas un nombre valide", "validators", "fr");
t.add("siqual.common.validators.range.minMessage", "Un minimum de {{ limit }} est requis", "validators", "fr");
t.add("siqual.common.validators.range.maxMessage", "Un maximum de {{ limit }} est permis", "validators", "fr");
t.add("siqual.common.validators.recaptcha.message", "Le recaptcha n'est pas valide", "validators", "fr");
t.add("siqual.common.validators.regex.message", "Cette valeur n'est pas valide", "validators", "fr");
t.add("siqual.common.validators.strongPassword.message", "Le mot de passe doit faire au moins 8 caract\u00e8res, contenir au moins une majuscule, une minuscule et un chiffre", "validators", "fr");
t.add("siqual.common.validators.time.message", "Cette valeur n'est pas un time valide", "validators", "fr");
t.add("siqual.common.validators.type.message", "La valeur {{ value }} n'est pas un type {{ type }} valide", "validators", "fr");
t.add("siqual.common.validators.uniqueEntity.message", "Cette valeur est d\u00e9j\u00e0 utilis\u00e9e", "validators", "fr");
t.add("siqual.common.validators.url.message", "Cette URL n'est pas valide", "validators", "fr");
t.add("siqual.admin.error.empty", "Merci de remplir ce champ", "validators", "fr");
t.add("siqual.admin.error.length.min", "Ce champ doit faire au moins {{ limit }} caract\u00e8res", "validators", "fr");
t.add("siqual.admin.error.length.max", "Ce champ ne doit pas d\u00e9passer {{ limit }} caract\u00e8res", "validators", "fr");
t.add("siqual.admin.error.range.min", "Cette valeur doit faire au minimum {{ limit }}", "validators", "fr");
t.add("siqual.admin.error.range.max", "Cette valeur ne peut d\u00e9passer {{ limit }}", "validators", "fr");
t.add("siqual.admin.error.url", "Merci d'entrer une URL valide", "validators", "fr");
t.add("siqual.admin.error.email", "Merci d'entrer une adresse email valide", "validators", "fr");
t.add("siqual.admin.error.select_picture", "Merci de s\u00e9lectionner une image (.jpg, .png ou .gif)", "validators", "fr");
t.add("siqual.admin.error.type", "La valeur {{ value }} n'est pas un type {{ type }} valide", "validators", "fr");
t.add("siqual.user.admin.group.role_dashboard.required", "Vous ne pouvez pas retirer la permission sur le tableau de bord", "validators", "fr");
t.add("siqual.user.admin.user.field.password.validation.invalid", "Les deux mots de passe doivent \u00eatre identiques", "validators", "fr");
t.add("fos_user.username.already_used", "Le nom d'utilisateur est d\u00e9j\u00e0 utilis\u00e9.", "validators", "fr");
t.add("fos_user.username.blank", "Entrez un nom d'utilisateur s'il vous plait.", "validators", "fr");
t.add("fos_user.username.short", "Le nom d'utilisateur est trop court.", "validators", "fr");
t.add("fos_user.username.long", "Le nom d'utilisateur est trop long.", "validators", "fr");
t.add("fos_user.email.already_used", "L'adresse e-mail est d\u00e9j\u00e0 utilis\u00e9e.", "validators", "fr");
t.add("fos_user.email.blank", "Entrez une adresse e-mail s'il vous plait.", "validators", "fr");
t.add("fos_user.email.short", "L'adresse e-mail est trop courte.", "validators", "fr");
t.add("fos_user.email.long", "L'adresse e-mail est trop longue.", "validators", "fr");
t.add("fos_user.email.invalid", "L'adresse e-mail est invalide.", "validators", "fr");
t.add("fos_user.password.blank", "Entrez un mot de passe s'il vous plait.", "validators", "fr");
t.add("fos_user.password.short", "Le mot de passe est trop court.", "validators", "fr");
t.add("fos_user.password.mismatch", "Les deux mots de passe ne sont pas identiques.", "validators", "fr");
t.add("fos_user.new_password.blank", "Entrez un nouveau mot de passe s'il vous plait.", "validators", "fr");
t.add("fos_user.new_password.short", "Le nouveau mot de passe est trop court.", "validators", "fr");
t.add("fos_user.current_password.invalid", "Le mot de passe est invalide.", "validators", "fr");
t.add("fos_user.group.blank", "Entrez un nom s'il vous plait.", "validators", "fr");
t.add("fos_user.group.short", "Le nom est trop court.", "validators", "fr");
t.add("fos_user.group.long", "Le nom est trop long.", "validators", "fr");
t.add("fos_group.name.already_used", "Le nom est d\u00e9j\u00e0 utilis\u00e9.", "validators", "fr");
t.add("app.dossier.admin.darva_mercure", "Un dossier ne peut pas \u00eatre DARVA et MERCURE en m\u00eame temps", "validators", "fr");
t.add("app.operation_expertise.admin.date_rapport_reconnaise_anterieur_date_expertise", "La date de reconnaisance doit \u00eatre post\u00e9rieure \u00e0 la date d'expertise", "validators", "fr");
t.add("app.operation_expertise.admin.date_expertise_non_null", "La date d'expertise est obligatoire", "validators", "fr");
t.add("app.type_expertise.admin.piece_site", "Un type d'expertise ne peut pas \u00eatre \u00e0 la fois sur pi\u00e8ces et sur site", "validators", "fr");
t.add("app.trame_document.admin.validation.unique", "Un document est d\u00e9j\u00e0 enregistr\u00e9 pour cette famille et cette proc\u00e9dure.", "validators", "fr");
})(Translator);
