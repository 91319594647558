/**
 * stacktable.js
 * Author & copyright (c) 2012: John Polacek
 * Dual MIT & GPL license
 *
 * Page: http://johnpolacek.github.com/stacktable.js
 * Repo: https://github.com/johnpolacek/stacktable.js/
 *
 * jQuery plugin for stacking tables on small screens
 *
 * Updated by Pierrick Aubin <pierrick.aubin@siqual.fr>
 */
!function($) {

    $.fn.stacktable = function(options) {
        var $tables = this,
            defaults = {id:'stacktable',hideOriginal:false},
            settings = $.extend({}, defaults, options);

        return $tables.each(function() {
            var $stacktable = $('<table class="'+settings.id+'"><tbody></tbody></table>');
            if (typeof settings.myClass !== undefined) $stacktable.addClass(settings.myClass);
            var markup = '';
            $table = $(this);
            $topRow = $table.find('tr').eq(0);
            $table.find('tr:not(.sub)').each(function(index,value) {
                markup += '<tr>';
                // for the first row, top left table cell is the head of the table
                if (index===0) {
                    markup += '<tr><th class="st-head-row st-head-row-main" colspan="2">'+$(this).children('th,td').eq(0).html()+'</th></tr>';
                }
                // for the other rows, put the left table cell as the head for that row
                // then iterate through the key/values
                else {
                    $(value).children('td').each(function(index,value) {
                        if (index===0) {
                            markup += '<tr><th class="st-head-row" colspan="2">'+$(this).html()+'</th></tr>';
                        } else {
                            if ($(this).html() !== ''){
                                markup += '<tr>';
                                if ($topRow.find('td,th').eq(index).html()){
                                    markup += '<td class="st-key">'+$topRow.find('td,th').eq(index).html()+'</td>';
                                } else {
                                    markup += '<td class="st-key"></td>';
                                }
                                markup += '<td class="st-val">'+$(this).html()+'</td>';
                                markup += '</tr>';
                            }
                        }
                    });
                }
            });
            $stacktable.append($(markup));
            $table.before($stacktable);
            if (settings.hideOriginal) $table.hide();
        });
    };

}(jQuery);