(function ($) {
    $.fn.isAfter = function (sel) {
        return this.prevAll().filter(sel).length !== 0;
    };

    $.fn.isBefore = function (sel) {
        return this.nextAll().filter(sel).length !== 0;
    };
})(jQuery);

function isIE() {
    ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
}

var initTinyMCE = function (id) {
    if (id == undefined || id == '') {
        id = "textarea.tinymce";
    }
    var spellchecker_locale = app_request_locale;

    if (spellchecker_locale == 'fr') {
        spellchecker_locale = 'fr_FR';
    }
    try {
        tinymce.init({
            selector: id,

            plugins: [
                Translator.trans('siqual.admin.tinyMCE.plugins.line1'),
                Translator.trans('siqual.admin.tinyMCE.plugins.line2'),
                Translator.trans('siqual.admin.tinyMCE.plugins.line3')
            ],

            toolbar1: tiny_mce_code + Translator.trans('siqual.admin.tinyMCE.toolbars.toolbar1'),
            toolbar2: Translator.trans('siqual.admin.tinyMCE.toolbars.toolbar2'),
            toolbar3: Translator.trans('siqual.admin.tinyMCE.toolbars.toolbar3'),

            menubar: false,
            toolbar_items_size: 'small',
            relative_urls: false,
            remove_script_host: false,
            convert_urls: false,
            language: app_request_locale,
            extended_valid_elements: "i[class]",
            content_css: "/bundles/siqualbootstrap/3.3.6/css/bootstrap.css,/bundles/siqualfonts/font-awesome/css/font-awesome.css,/bundles/siqualfonts/icomoon/css/icomoon.css,/bundles/siqualfonts/siqualfont/1.0.0/css/font-siqual.css",
            external_filemanager_path: "/bundles/siqualmedia/filemanager/",
            filemanager_title: Translator.trans('siqual.admin.common.filemanager'),
            external_plugins: {"filemanager": "/bundles/siqualmedia/filemanager/plugin.min.js"},
            style_formats: [
                {title: Translator.trans('siqual.admin.tinyMCE.styles.lead'), inline: 'span', classes: 'lead'},
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style1'),
                    inline: 'span',
                    classes: 'style-tinymce-1'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style2'),
                    inline: 'span',
                    classes: 'style-tinymce-2'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style3'),
                    inline: 'span',
                    classes: 'style-tinymce-3'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style4'),
                    inline: 'span',
                    classes: 'style-tinymce-4'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style5'),
                    inline: 'span',
                    classes: 'style-tinymce-5'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style6'),
                    inline: 'span',
                    classes: 'style-tinymce-6'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style7'),
                    inline: 'span',
                    classes: 'style-tinymce-7'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style8'),
                    inline: 'span',
                    classes: 'style-tinymce-8'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style9'),
                    inline: 'span',
                    classes: 'style-tinymce-9'
                },
                {
                    title: Translator.trans('siqual.admin.tinyMCE.styles.style10'),
                    inline: 'span',
                    classes: 'style-tinymce-10'
                }
            ],
            spellchecker_language: spellchecker_locale,
            spellchecker_rpc_url: 'spellchecker.php',
            style_formats_merge: true,
            setup: function (ed) {
                ed.on("keydown", function (event) {
                    var $openedModal = $('.modal.in');
                    if ((event.ctrlKey || event.metaKey) && event.which == 83) {
                        $openedModal.find('.submitBtn').click();
                        event.preventDefault();
                        return false;
                    }
                });
            }
        });
    } catch (err) {
        console.log(err);
        // caught the reference error
        // code here will execute **only** if variable was never declared
    }
};

var initEditArea = function () {
    if ($('.editArea:not(.triggered)').length > 0) {
        $.each($('.editArea:not(.triggered)'), function () {
            $(this).addClass('triggered');
            editAreaLoader.init({
                id: $(this).attr('id')
                , start_highlight: true	// if start with highlight
                , allow_resize: "both"
                , allow_toggle: false
                , word_wrap: true
                , language: app_request_locale
                , syntax: $(this).data('syntax')
                , min_height: $(this).data('height') ? $(this).data('height') : 400
            });
        });
    }
};

var iconSelect = function (data) {
    if (data.id != '') {
        return $('<span><i class="' + data.id + '"></i> ' + data.id + '</span>');
    } else {
        return $('<span>' + data.text + '</span>');
    }
};

String.prototype.replaceAll = function (search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

var htmlentities = function(str) {
    var encodedStr = str;
    if (str && typeof str === 'string') {
        encodedStr = str.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
            return '&#'+i.charCodeAt(0)+';';
        });
    }
    return encodedStr;
};

var html_entity_decode = function(str) {
    try {
        var decodeArea = document.createElement('textarea');
        decodeArea.innerHTML = str;
        return decodeArea.value;
        decodeArea.parentNode.removeChild(decodeArea);
    } catch(e) {
        $('body').append('<div id="htmlconverter" style="display:none;"></div>');
        document.getElementById("htmlconverter").innerHTML = '<textarea id="innerConverter">' + str + '</textarea>';
        var content = document.getElementById("innerConverter").value;
        document.getElementById("htmlconverter").innerHTML = "";
        return content;
    }
};

var strip_tags = function(str) {
    var div = document.createElement("div");
    div.innerHTML = str;
    var text = div.textContent || div.innerText || "";
    return text;
};

var initFormTypes = function (element) {
    element = element || 'body';

    $.each($(element).find('.make-switch'), function (k, v) {
        if (!$(v).hasClass('has-switch')) {
            $(v)['bootstrapSwitch']();
        }
    });

    $.each($(element).find('.select-icon'), function (k, v) {
        if ($(v).is('select')) {
            $(v).select2({
                openOnEnter: true,
                templateResult: iconSelect,
                templateSelection: iconSelect
            });
        }
    });

    $.each($(element).find('.select2'), function (k, v) {
        if ($(v).is('select')) {
            $.each($(v).find('option'), function (k, v) {
                $(v).html(htmlentities(html_entity_decode(html_entity_decode($(v).html()))));
            });
            $(v).select2({
                openOnEnter: true,
                allowClear: true,
                caseSensitive: false,
                templateResult: function (d) {
                    return $('<span>' + d.text + '</span>');
                },
                templateSelection: function (d) {
                    return $('<span>' + d.text + '</span>');
                }
            });
        } else {
            if ($(v).attr('id') !== undefined) {
                var id = 'select2_' + $(v).attr('id');
                $(v).hide().removeClass('select2').after('<select id="' + id + '" data-target="' + $(v).attr('id') + '" class="select2_tags" multiple="true" data-placeholder="' + (typeof $(v).attr('data-placeholder') !== typeof undefined ? $(v).attr('data-placeholder') : "") + '"></select>');
                $.each($(v).val().split(','), function (key, val) {
                    if (val != "") {
                        $('#' + id).append('<option selected="selected">' + htmlentities(html_entity_decode(html_entity_decode(val))) + '</option>');
                    }
                });
                $('#' + id).select2({
                    tags: true,
                    tokenSeparators: [","],
                    openOnEnter: true,
                    allowClear: true,
                    caseSensitive: false,
                    templateResult: function (d) {
                        return $('<span>' + d.text + '</span>');
                    },
                    templateSelection: function (d) {
                        return $('<span>' + d.text + '</span>');
                    }
                });
                $(document).delegate('#' + id, 'change', function () {
                    $(v).val($(this).val());
                });
            }
        }
    });

    $.each($(element).find('.select2WithAdd'), function (k, v) {
        if ($(v).is('select')) {
            $.each($(v).find('option'), function (k, v) {
                $(v).html(htmlentities(html_entity_decode(html_entity_decode($(v).html()))));
            });
            $(v).select2({
                openOnEnter: true,
                allowClear: true,
                selectOnBlur: true,
                tags: true,
                templateResult: function (d) {
                    return $('<span>' + d.text + '</span>');
                },
                templateSelection: function (d) {
                    return $('<span>' + d.text + '</span>');
                }
            });
        } else {
            if ($(v).attr('id') !== undefined) {
                var id = 'select2_' + $(v).attr('id');
                $(v).hide().removeClass('select2').after('<select id="' + id + '" data-target="' + $(v).attr('id') + '" class="select2_tags" multiple="true" data-placeholder="' + (typeof $(v).attr('data-placeholder') !== typeof undefined ? $(v).attr('data-placeholder') : "") + '"></select>');
                $.each($(v).val().split(','), function (key, val) {
                    if (val != "") {
                        $('#' + id).append('<option selected="selected">' + htmlentities(html_entity_decode(html_entity_decode(val))) + '</option>');
                    }
                });
                $('#' + id).select2({
                    tags: true,
                    tokenSeparators: [","],
                    openOnEnter: true,
                    allowClear: true,
                    selectOnBlur: true,
                    caseSensitive: false,
                    templateResult: function (d) {
                        return $('<span>' + d.text + '</span>');
                    },
                    templateSelection: function (d) {
                        return $('<span>' + d.text + '</span>');
                    }
                });
                $(document).delegate('#' + id, 'change', function () {
                    $(v).val($(this).val());
                });
            }
        }
    });

    $.each($(element).find('[data-limit-max]'), function (k, v) {
        var max = parseInt($(v).data('limit-max'));
        var warning = parseInt($(v).data('limit-warning') != "" ? $(v).data('limit-warning') : max - 20);

        $(v).textLimit(max, function (length, limit, reached) {
            var $desc;
            if (!$(v).parent().find('.help-limit').length) {
                $desc = $(v).after('<div class="help-block help-limit"></div>');
            } else {
                $desc = $(v).parent().find('.help-limit');
            }

            var nb = limit - length;
            if (length >= warning) {
                $desc.css('color', 'orange');
                $(v).addClass('limit-warning');
            } else {
                $desc.css('color', 'inherit');
                $(v).removeClass('limit-warning');
            }

            if (length == 0) {
                $desc.text("");
            } else {
                $desc.text(Translator.transChoice("siqual.common.limit", nb, {"count": nb, "total": length}));
            }
        });
    });

    if ($(element).find(".datetime_picker").length > 0) {
        $(element).find(".datetime_picker").datetimepicker({
            format: Translator.trans("siqual.common.datetime_picker.datetime"),
            autoclose: true,
            todayBtn: true,
            language: app_request_locale,
            container: ".app-body",
            showMeridian: parseInt(Translator.trans("siqual.common.datetime_picker.showMeridian"))
        });
    }
    if ($(element).find(".date_picker").length > 0) {
        $(element).find(".date_picker").datetimepicker({
            format: Translator.trans("siqual.common.datetime_picker.date"),
            autoclose: true,
            todayBtn: true,
            minView: 2,
            language: app_request_locale,
            container: ".app-body"
        });
    }
    if ($(element).find(".time_picker").length > 0) {
        var now = new Date();
        $(element).find(".time_picker").datetimepicker({
            format: Translator.trans("siqual.common.datetime_picker.time"),
            autoclose: true,
            maxView: 1,
            startView: 1,
            startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
            endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 59),
            language: app_request_locale,
            container: ".app-body",
            formatViewType: "time",
            showMeridian: parseInt(Translator.trans("siqual.common.datetime_picker.showMeridian"))
        });
    }

    if (typeof $.fn.fancybox !== 'undefined') {
        $(element).find('.iframe-btn').fancybox({
            'width': 880,
            'height': 570,
            'type': 'iframe',
            'autoScale': false
        });
    }

    $(document).delegate('.date .input-group-addon', 'click', function () {
        $(this).closest('.date').find('input').focus();
    });

    $(element).find('table.table:not(.unstackable)').addClass('hidden-sm').addClass('hidden-xs').stacktable({myClass: 'stacktable table visible-sm visible-xs'});

    bindFocusSelect2();
};

var publishUnpublish = function () {
    $('.publish-unpublish-ajax').click(function (e) {
        e.preventDefault();
        var $this = $(this);
        var $span = $this.find('span');
        var $i = $this.find('i');
        $.ajax({
            type: "GET",
            url: $(this).data('href'),
            success: function (result) {
                if (result == 'OK') {
                    $i.attr('class', $i.data('class'));
                    $i.toggleClass('icon-eye-open').toggleClass('icon-eye-close');
                    $span.toggleClass('btn-success').toggleClass('btn-danger');
                    if ($i.hasClass('icon-eye-open')) {
                        toastr.success(Translator.trans('siqual.admin.common.publish_unpublish.publish'));
                    } else {
                        toastr.success(Translator.trans('siqual.admin.common.publish_unpublish.unpublish'));
                    }
                } else {
                    toastr.success(Translator.trans('siqual.admin.common.publish_unpublish.error'));
                }
            }
        });
    });
};

var setResponsive = function () {
    var $content = $('#content');
    var width = $content.width();
    $content.data('width', width).removeClass('is-xs').removeClass('is-sm').removeClass('is-md').removeClass('is-lg');
    if (width < 768) {
        $content.addClass('is-xs');
    } else if (width < 992) {
        $content.addClass('is-sm');
    } else if (width < 1200) {
        $content.addClass('is-md');
    } else {
        $content.addClass('is-lg');
    }
    setTimeout(function () {
        doMasonry();
    }, 110);
};

var doMasonry = function () {
    $('.masonry-items').masonry({
        itemSelector: '.masonry-item'
    });
};

var initPulsate = function () {
    $.each($('.pulsate'), function (k, v) {
        var color = $(v).data('pulsate-color') !== undefined ? $(v).data('pulsate-color') : "#bf1c56";
        var reach = $(v).data('pulsate-reach') !== undefined ? $(v).data('pulsate-reach') : 15;
        var repeat = $(v).data('pulsate-repeat') !== undefined ? $(v).data('pulsate-repeat') : -1;
        var speed = $(v).data('pulsate-speed') !== undefined ? $(v).data('pulsate-speed') : 1000;

        $(v).pulsate({
            color: color,
            reach: reach,
            repeat: repeat,
            speed: speed,
            glow: true
        });
    });
};

var resizeInputGroupSelect2 = function () {
    $.each($('.input-group .select2'), function (k, v) {
        var $formGroup = $(v).closest('.form-group');
        var $addOn = $formGroup.find('.input-group-addon:not(.hide)');
        if ($addOn.length) {
            var $formSpan = $formGroup.find('.select2-container--default .select2-selection--single .select2-selection__rendered > span:not(.select2-selection__clear)');
            $formSpan.removeAttr('style');
            $formSpan
                .css('overflow', 'hidden')
                .css('display', 'inline-block')
                .css('text-overflow', 'ellipsis')
                .width($formGroup.width() - $addOn.outerWidth() - 38);
        }
    });
};

var resizeFileInput = function () {
    $.each($('.fileinput-filename'), function (k, v) {
        var $input = $(v).closest('.form-control');
        $(v).css('width', $input.outerWidth() - 45);
    });
};

var bindFocusSelect2 = function() {
    if (!isIE()){
        $.each($('.select2'), function(k, v) {
            var $selection = $(v).find('.select2-selection');
            if (!$selection.hasClass('select2-selection--multiple')) {
                $(v).bind('focusin', function (e) {
                    if (e.originalEvent) {
                        $(this).siblings('select').select2('open');
                    }
                });
            }
        });
    }
};

$(document).ready(function () {
    if (!isIE()) {
        $(document).on('select2:close', 'select', function (e) {
            $('.select2').unbind('focusin');
            $(this).focus();
            bindFocusSelect2();
        });
        bindFocusSelect2();
    }

    var $hasError = $('.form-group.has-error');
    if ($hasError.length) {
        $.each($hasError, function (k, v) {
            var $tabPane = $(v).closest('.tab-pane');
            var $navTab = $('a[data-toggle=tab][href="#' + $tabPane.attr('id') + '"]');
            $navTab.addClass('has-error');
            if ($navTab.parent().hasClass('active')) {
                $tabPane.closest('.panel-body').addClass('error-border');
            }
            var $tabPaneParent = $tabPane.parent().closest('.tab-pane');
            if ($tabPaneParent.length) {
                $('a[data-toggle=tab][href="#' + $tabPaneParent.attr('id') + '"]').addClass('has-error');
            }
        });
    }

    $(document).delegate('[data-toggle="tab"]', 'click', function () {
        if (!($(this).hasClass('no-focus'))) {
            var $content = $('#content');
            var lastPos = $content.scrollTop();
            var $tab = $(this);
            if ($tab.attr('href').slice(0, 1) == '#') {
                location.hash = $tab.attr('href').replace('#', '');
                $content.scrollTop(lastPos);

                var $form = $tab.closest('.nav').closest('.panel').find('form');
                if ($form.length) {
                    var $anchor = $form.find('input[name=anchor]');
                    if (!$anchor.length) {
                        $form.append('<input type="hidden" name="anchor" />');
                    }
                    $anchor = $form.find('input[name=anchor]');
                    $anchor.val($tab.attr('href'));
                }
            }
            resizeInputGroupSelect2();
        }
        if ($(this).hasClass('has-error')) {
            $($(this).attr('href')).closest('.panel-body').addClass('error-border');
        } else {
            $($(this).attr('href')).closest('.panel-body').removeClass('error-border');
        }
    });

    initPulsate();
    initFormTypes();
    initTinyMCE();
    publishUnpublish();

    var $clickedCheckbox;
    var shiftIsPressed = false;

    $(document).keydown(function (event) {
        if (window.event) {
            shiftIsPressed = !!window.event.shiftKey; // typecast to boolean
        } else {
            shiftIsPressed = !!event.shiftKey;
        }
    });

    $(document).keyup(function () {
        shiftIsPressed = false;
    });

    $(document).delegate('input[type="checkbox"][name="_ids[]"]', 'click', function () {
        if (shiftIsPressed && $clickedCheckbox.length) {
            var $fromLine = $clickedCheckbox.closest('tr');
            var $toLine = $(this).closest('tr');
            var checked = $(this).is(':checked');

            if ($toLine.isBefore($fromLine)) {
                $.each($toLine.nextAll(), function (k, elmt) {
                    if ($(elmt).isBefore($fromLine)) {
                        $(elmt).find('input[type="checkbox"][name="_ids[]"]').prop('checked', checked).trigger('change');
                    }
                });
                $clickedCheckbox.prop('checked', checked).trigger('change');
            } else if ($toLine.isAfter($fromLine)) {
                $.each($toLine.prevAll(), function (k, elmt) {
                    if ($(elmt).isAfter($fromLine)) {
                        $(elmt).find('input[type="checkbox"][name="_ids[]"]').prop('checked', checked).trigger('change');
                    }
                });
                $clickedCheckbox.prop('checked', checked).trigger('change');
            }
        }
        $clickedCheckbox = $(this);
    });

    $(document).delegate('.checkbox label', 'click', function () {
        var $this = $(this).find('input[type=checkbox]');
        if (shiftIsPressed && $clickedCheckbox.length) {
            var $fromLine = $clickedCheckbox.closest('tr');
            var $toLine = $this.closest('tr');
            var checked = !$this.is(':checked');

            if ($toLine.isBefore($fromLine)) {
                $.each($toLine.nextAll(), function (k, elmt) {
                    if ($(elmt).isBefore($fromLine)) {
                        $(elmt).find('input[type="checkbox"][name="_ids[]"]').prop('checked', checked).trigger('change');
                    }
                });
                $clickedCheckbox.prop('checked', checked).trigger('change');
            } else if ($toLine.isAfter($fromLine)) {
                $.each($toLine.prevAll(), function (k, elmt) {
                    if ($(elmt).isAfter($fromLine)) {
                        $(elmt).find('input[type="checkbox"][name="_ids[]"]').prop('checked', checked).trigger('change');
                    }
                });
                $clickedCheckbox.prop('checked', checked).trigger('change');
            }
        }
        $clickedCheckbox = $this;
    });

    $(document).delegate('.side-nav-item > a[href="#"]', 'click', function (e) {
        e.preventDefault();
        var $sub = $(this).parent().find('.side-nav-child:first');
        var $parent = $(this).parent().closest('.side-nav-child');
        $parent.css('overflow', 'hidden').scrollTop(0);
        $sub.addClass('open').css('overflow', 'auto');
    });

    $(document).delegate('.side-nav-item-heading > a.side-nav-back', 'click', function (e) {
        e.preventDefault();
        var $sub = $(this).closest('.side-nav-child');
        $(this).parent().closest('.side-nav-child').parent().closest('.side-nav-child').css('overflow', 'auto');
        $sub.removeClass('open');
    });

    $(document).delegate('#modal-batch-actions-confirm', 'click', function (e) {
        e.preventDefault();
        var $button = $('[data-toggle="modal"][data-target="#modal-confirm-batch-actions"]');
        var $form = null;
        if ($('#batchActions').length) {
            $form = $('#batchActions');
        } else {
            var $ids = $('[name*="_ids[]"]');
            if ($ids.length) {
                $form = $ids.closest('form');
            } else {
                $.each($button.closest('.app-body').find('form'), function (k, v) {
                    if ($form == null && $(v).closest('.form-search').length == 0) {
                        $form = $(v);
                    }
                });
            }
        }
        var $action = $form.find('[name="_action"]');
        if ($action.length) {
            $action.val($button.closest('.row').find('[name="_action"]').val());
        } else {
            $form.append('<input type="hidden" name="_action" value="' + $button.closest('.row').find('[name="_action"]').val() + '" />');
        }
        $form.submit();
    });

    $(document).delegate('input[type="checkbox"].group-checkable', 'click', function () {
        if ($('#content').hasClass('is-xs')) {
            $(this).closest('.app-body').find('input[type="checkbox"].checkboxes').prop('checked', $(this).is(':checked')).trigger('change');
        } else {
            $(this).closest('table').find('input[type="checkbox"].checkboxes').prop('checked', $(this).is(':checked')).trigger('change');
        }
    });

    $(document).delegate('#toggle-content', 'click', function () {
        /* Patch fond gris sur la dernière version de Google Chrome */
        var $sideLeft = $('.side-left');
        if ($sideLeft.css('z-index') == 2) {
            $sideLeft.removeAttr('style').css('z-index', '3');
        } else {
            $sideLeft.removeAttr('style').css('z-index', '2');
        }
        /* Fin Patch */

        var $content = $('#content');
        $content.toggleClass('content-lg');
        setResponsive();
        if (!$content.hasClass('is-xs')) {
            $.ajax({
                type: "POST",
                url: Routing.generate('siqual_admin_toggle_content'),
                data: {
                    open: !$content.hasClass('content-lg')
                }
            });
        }

        resizeInputGroupSelect2();
        resizeFileInput();
    });

    $(document).delegate('#toggle-config-menu', 'click', function () {
        var $configs = $('.header-actions-config');
        if ($configs.hasClass('active')) {
            $configs.removeClass('active');
        } else {
            $configs.addClass('active');
        }
    });

    if ($(window).width() <= 767) {
        $('#content').addClass('content-lg');
    }

    var $sideNavItemActive = $('.side-nav-item.active');
    $sideNavItemActive.find('.side-nav-child:first').addClass('open');
    $('.side-nav-child').css('overflow', 'hidden');
    $('.side-nav-child.open').last().css('overflow', 'auto').scrollTop(0);

    $(document).delegate('[data-expand]', 'click', function () {
        $($(this).data('expand')).toggleClass("expand");
        setResponsive();
    });

    $(window).resize(function () {
        setResponsive();
    });

    window.addEventListener("orientationchange", function() {
        setResponsive();
    }, false);

    setResponsive();

    $('[data-toggle="tooltip"]').tooltip();

    $(document).keydown(function (evt) {
        var $contentMain = $('#content-main');
        var speed = 50;
        if (evt.keyCode == 40) { // down arrow
            $contentMain.animate({scrollTop: $contentMain.scrollTop() + 50}, speed);
        } else if (evt.keyCode == 38) { // top arrow
            $contentMain.animate({scrollTop: $contentMain.scrollTop() - 50}, speed);
        }
    });

    $.each($('.side-nav-item .side-nav-child'), function (k, v) {
        var nb = 0;
        $.each($(v).find('.badge'), function (key, val) {
            nb += parseInt($(val).html());
        });
        if (nb > 0) {
            $(v).closest('.side-nav-item').find('.arrow').before('<span class="badge margin-right-10">' + nb + '</span>');
        }
    });

    $(document).delegate('[data-edit-link]', 'change', function () {
        var $elmt = $(this);
        var $addon = $elmt.closest('.input-group').find('.input-group-addon');
        var $link = $addon.find('a');
        var $icon = $link.find('i');
        if ($link.length) {
            if ($elmt.val() !== null && $elmt.val() !== '') {
                var params = {};
                params[$elmt.data('route-param-name')] = $elmt.val();
                $link.attr('href', Routing.generate($elmt.data('route'), params));
                $link.removeClass('hide');
                $icon.addClass('icon-external-link').removeClass('icon-plus-circle');
            } else {
                if ($elmt.data('route-create') !== undefined) {
                    $link.attr('href', Routing.generate($elmt.data('route-create')));
                    $link.removeClass('hide');
                    $icon.removeClass('icon-external-link').addClass('icon-plus-circle');
                } else {
                    $link.addClass('hide');
                }
            }
        }
    });
    $(document).delegate('.select2', 'change select2.change', function () {
        resizeInputGroupSelect2();
    });
    $(document).delegate('.fileinput-filename', 'change', function () {
        resizeFileInput();
    });
    $(window).resize(function () {
        resizeInputGroupSelect2();
        resizeFileInput();
    });
    setTimeout(function () {
        resizeInputGroupSelect2();
        resizeFileInput();
    }, 100);

    $(document).delegate('.clearDate', 'click', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(this).addClass('hide');
        $(this).closest('.input-group').find('input').val('').blur();
    });

    $(document).delegate('.date_picker, .time_picker, .datetime_picker', 'change', function () {
        if ($(this).val() != "") {
            $(this).closest('.input-group').find('.clearDate').removeClass('hide');
        } else {
            $(this).closest('.input-group').find('.clearDate').addClass('hide');
        }
    });

    $(document).click(function () {
        var $temp = $('.table-responsive').find('.dropdown-toggle')[0];
        if ($temp !== undefined) {
            var $tableResponsive = $temp.closest('.table-responsive');
            $tableResponsive = $($tableResponsive);
            if ($tableResponsive.length) {
                $tableResponsive.removeAttr('style');
            }
            var $dropdownExpanded = $('.dropdown-toggle[aria-expanded="true"]');
            if ($dropdownExpanded.length) {
                $tableResponsive = $dropdownExpanded.closest('.table-responsive');
                if ($tableResponsive.length) {
                    $tableResponsive.height($tableResponsive[0].scrollHeight);
                }
            }
        }
    });

    $(document).delegate('.dropdown-toggle', 'click', function () {
        var $tableResponsive = $(this).closest('.table-responsive');
        if ($tableResponsive.length) {
            $tableResponsive.removeAttr('style');
            $tableResponsive.height($tableResponsive[0].scrollHeight);
        }
    });

    // Empeche le scroll de la page si on appuit sur les fleche haut/bas dans un select2 ou dans le textarea d'une modal
    $(document).delegate('.modal, .select2-container', 'keydown', function(e) {
        switch (e.keyCode) {
            case 37: // Left
            case 39: // Right
            case 38: // Up
            case 40: // Down
            case 32: // Space
                e.stopPropagation();
                break;
            default:
                break; // do not block other keys
        }
    });

    $(document).delegate('[data-toggle="buttons-radio"] label', 'click', function() {
        var $this = $(this);
        var $btnGroup = $this.closest('.btn-group');
        $btnGroup.find('label.btn').removeClass('active').attr('aria-pressed', 'false');
        setTimeout(function() {
            $this.addClass('active').attr('aria-pressed', 'true');
            $this.find('input').prop('checked', true);
        }, 100)
    });
});