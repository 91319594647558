(function (t) {
// fr
t.add("siqual.common.locale", "fr", "messages", "fr");
t.add("siqual.common.cancel", "Annuler", "messages", "fr");
t.add("siqual.common.search.title", "Recherche", "messages", "fr");
t.add("siqual.common.search.active_fields", "{0,1}1 filtre actif|]1,Inf]%nb% filtres actifs", "messages", "fr");
t.add("siqual.common.choice.placeholder", "Choisir dans la liste", "messages", "fr");
t.add("siqual.common.close", "Fermer", "messages", "fr");
t.add("siqual.common.confirm", "Confirmer", "messages", "fr");
t.add("siqual.common.datetime_format.date", "dd\/MM\/yyyy", "messages", "fr");
t.add("siqual.common.datetime_format.datetime", "dd\/MM\/yyyy - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_format.time", "HH:mm", "messages", "fr");
t.add("siqual.common.datetime_mask.date", "99\/99\/9999", "messages", "fr");
t.add("siqual.common.datetime_mask.datetime", "99\/99\/9999 - 99:99", "messages", "fr");
t.add("siqual.common.datetime_mask.time", "99:99", "messages", "fr");
t.add("siqual.common.datetime_moment.date", "DD\/MM\/YYYY", "messages", "fr");
t.add("siqual.common.datetime_moment.date_long", "Do MMMM YYYY", "messages", "fr");
t.add("siqual.common.datetime_moment.date_short", "Do MMM YYYY", "messages", "fr");
t.add("siqual.common.datetime_moment.datetime", "DD\/MM\/YYYY - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_moment.datetime_long", "Do MMMM YYYY - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_moment.datetime_short", "Do MMM YYYY - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_moment.day", "DD", "messages", "fr");
t.add("siqual.common.datetime_moment.day_long", "dddd", "messages", "fr");
t.add("siqual.common.datetime_moment.day_short", "ddd", "messages", "fr");
t.add("siqual.common.datetime_moment.month", "MM", "messages", "fr");
t.add("siqual.common.datetime_moment.month_long", "MMMM", "messages", "fr");
t.add("siqual.common.datetime_moment.month_short", "MMM", "messages", "fr");
t.add("siqual.common.datetime_moment.time", "HH:mm", "messages", "fr");
t.add("siqual.common.datetime_moment.year", "YYYY", "messages", "fr");
t.add("siqual.common.datetime_mysql.date", "Y-m-d", "messages", "fr");
t.add("siqual.common.datetime_mysql.datetime", "Y-m-d H:i:s", "messages", "fr");
t.add("siqual.common.datetime_mysql.time", "H:i:s", "messages", "fr");
t.add("siqual.common.datetime_php.date", "d\/m\/Y", "messages", "fr");
t.add("siqual.common.datetime_php.date_long", "d F Y", "messages", "fr");
t.add("siqual.common.datetime_php.date_short", "d M Y", "messages", "fr");
t.add("siqual.common.datetime_php.datetime", "d\/m\/Y - H:i", "messages", "fr");
t.add("siqual.common.datetime_php.datetime_long", "d F Y - H:i", "messages", "fr");
t.add("siqual.common.datetime_php.datetime_short", "d M Y - H:i", "messages", "fr");
t.add("siqual.common.datetime_php.day", "d", "messages", "fr");
t.add("siqual.common.datetime_php.day_long", "l", "messages", "fr");
t.add("siqual.common.datetime_php.day_short", "D", "messages", "fr");
t.add("siqual.common.datetime_php.month", "m", "messages", "fr");
t.add("siqual.common.datetime_php.month_long", "F", "messages", "fr");
t.add("siqual.common.datetime_php.month_short", "M", "messages", "fr");
t.add("siqual.common.datetime_php.time", "H:i", "messages", "fr");
t.add("siqual.common.datetime_php.year", "Y", "messages", "fr");
t.add("siqual.common.datetime_picker.date", "dd\/mm\/yyyy", "messages", "fr");
t.add("siqual.common.datetime_picker.datetime", "dd\/mm\/yyyy - hh:ii", "messages", "fr");
t.add("siqual.common.datetime_picker.showMeridian", "0", "messages", "fr");
t.add("siqual.common.datetime_picker.time", "hh:ii", "messages", "fr");
t.add("siqual.common.datetime_twig.date", "dd\/MM\/y", "messages", "fr");
t.add("siqual.common.datetime_twig.date_long", "dd MMMM y", "messages", "fr");
t.add("siqual.common.datetime_twig.date_short", "dd MMM y", "messages", "fr");
t.add("siqual.common.datetime_twig.datetime", "dd\/MM\/y - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_twig.datetime_long", "dd MMMM y - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_twig.datetime_short", "dd MMM y - HH:mm", "messages", "fr");
t.add("siqual.common.datetime_twig.day", "dd", "messages", "fr");
t.add("siqual.common.datetime_twig.day_long", "EEEE", "messages", "fr");
t.add("siqual.common.datetime_twig.day_short", "EEE", "messages", "fr");
t.add("siqual.common.datetime_twig.month", "MM", "messages", "fr");
t.add("siqual.common.datetime_twig.month_long", "MMMM", "messages", "fr");
t.add("siqual.common.datetime_twig.month_short", "MMM", "messages", "fr");
t.add("siqual.common.datetime_twig.time", "HH:mm", "messages", "fr");
t.add("siqual.common.datetime_twig.year", "y", "messages", "fr");
t.add("siqual.common.days.sunday.long", "Dimanche", "messages", "fr");
t.add("siqual.common.days.sunday.short", "Dim", "messages", "fr");
t.add("siqual.common.days.monday.long", "Lundi", "messages", "fr");
t.add("siqual.common.days.monday.short", "Lun", "messages", "fr");
t.add("siqual.common.days.tuesday.long", "Mardi", "messages", "fr");
t.add("siqual.common.days.tuesday.short", "Mar", "messages", "fr");
t.add("siqual.common.days.wednesday.long", "Mercredi", "messages", "fr");
t.add("siqual.common.days.wednesday.short", "Mer", "messages", "fr");
t.add("siqual.common.days.thursday.long", "Jeudi", "messages", "fr");
t.add("siqual.common.days.thursday.short", "Jeu", "messages", "fr");
t.add("siqual.common.days.friday.long", "Vendredi", "messages", "fr");
t.add("siqual.common.days.friday.short", "Ven", "messages", "fr");
t.add("siqual.common.days.saturday.long", "Samedi", "messages", "fr");
t.add("siqual.common.days.saturday.short", "Sam", "messages", "fr");
t.add("siqual.common.empty_value", "S\u00e9lectionner", "messages", "fr");
t.add("siqual.common.error.email.invalid", "L'adresse email n'est pas valide", "messages", "fr");
t.add("siqual.common.error.url.invalid", "L'url n'est pas valide", "messages", "fr");
t.add("siqual.common.exception.403.title", "Acc\u00e8s prot\u00e9g\u00e9", "messages", "fr");
t.add("siqual.common.exception.403.content", "D\u00e9sol\u00e9, mais vous n'\u00eates pas autoris\u00e9 \u00e0 acc\u00e9der \u00e0 cette page. Veuillez contacter votre administrateur si vous pensez qu'il s'agit d'une erreur.", "messages", "fr");
t.add("siqual.common.exception.403.code", "403", "messages", "fr");
t.add("siqual.common.exception.404.title", "Page non trouv\u00e9e", "messages", "fr");
t.add("siqual.common.exception.404.content", "D\u00e9sol\u00e9 mais la page que vous cherchez est introuvable. Veuillez contacter votre administrateur si vous pensez qu'il s'agit d'une erreur.", "messages", "fr");
t.add("siqual.common.exception.404.code", "404", "messages", "fr");
t.add("siqual.common.exception.429.title", "Acc\u00e8s temporairement bloqu\u00e9", "messages", "fr");
t.add("siqual.common.exception.429.content", "Notre syst\u00e8me a d\u00e9tect\u00e9 trop d'erreurs d'authentification. Votre acc\u00e8s a \u00e9t\u00e9 temporairement bloqu\u00e9 pour une dur\u00e9e de <strong>15 minutes<\/strong>. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter votre Administrateur.", "messages", "fr");
t.add("siqual.common.exception.429.code", "429", "messages", "fr");
t.add("siqual.common.exception.500.title", "Une erreur est survenue", "messages", "fr");
t.add("siqual.common.exception.500.content", "<strong>Pas d'inqui\u00e9tude !<\/strong> Notre \u00e9quipe vient d'\u00eatre pr\u00e9venue de cet incident et interviendra dans les plus brefs d\u00e9lais pour r\u00e9soudre celui-ci.", "messages", "fr");
t.add("siqual.common.exception.500.code", "500", "messages", "fr");
t.add("siqual.common.exception.acces_denied", "D\u00e9sol\u00e9, mais vous devez \u00eatre authentifi\u00e9 pour pouvoir acc\u00e9der \u00e0 cette page", "messages", "fr");
t.add("siqual.common.exception.back_to_home", "Retourner \u00e0 l'accueil", "messages", "fr");
t.add("siqual.common.exception.title", "Erreur", "messages", "fr");
t.add("siqual.common.global_search.form.fields.query.label", "Rechercher", "messages", "fr");
t.add("siqual.common.global_search.form.fields.query.placeholder", "Rechercher", "messages", "fr");
t.add("siqual.common.new", "Nouveau", "messages", "fr");
t.add("siqual.common.langs.title", "Langues", "messages", "fr");
t.add("siqual.common.langs.english", "English", "messages", "fr");
t.add("siqual.common.langs.french", "Fran\u00e7ais", "messages", "fr");
t.add("siqual.common.langs.en", "English", "messages", "fr");
t.add("siqual.common.langs.fr", "Fran\u00e7ais", "messages", "fr");
t.add("siqual.common.langs.short.en", "EN", "messages", "fr");
t.add("siqual.common.langs.short.fr", "FR", "messages", "fr");
t.add("siqual.common.limit", "{0}Vous avez utilis\u00e9 vos %total% caract\u00e8res|{1}Il reste %count% caract\u00e8re|[2,Inf]Il reste %count% caract\u00e8res", "messages", "fr");
t.add("siqual.common.loadMore", "Afficher plus", "messages", "fr");
t.add("siqual.common.loadNext", "Afficher la suite", "messages", "fr");
t.add("siqual.common.mailto", "Contacter par email", "messages", "fr");
t.add("siqual.common.months.january.long", "Janvier", "messages", "fr");
t.add("siqual.common.months.january.short", "Jan", "messages", "fr");
t.add("siqual.common.months.february.long", "F\u00e9vrier", "messages", "fr");
t.add("siqual.common.months.february.short", "F\u00e9v", "messages", "fr");
t.add("siqual.common.months.march.long", "Mars", "messages", "fr");
t.add("siqual.common.months.march.short", "Mar", "messages", "fr");
t.add("siqual.common.months.april.long", "Avril", "messages", "fr");
t.add("siqual.common.months.april.short", "Avr", "messages", "fr");
t.add("siqual.common.months.may.long", "Mai", "messages", "fr");
t.add("siqual.common.months.may.short", "Mai", "messages", "fr");
t.add("siqual.common.months.june.long", "Juin", "messages", "fr");
t.add("siqual.common.months.june.short", "Juin", "messages", "fr");
t.add("siqual.common.months.july.long", "Juillet", "messages", "fr");
t.add("siqual.common.months.july.short", "Juil", "messages", "fr");
t.add("siqual.common.months.august.long", "Ao\u00fbt", "messages", "fr");
t.add("siqual.common.months.august.short", "Ao\u00fb", "messages", "fr");
t.add("siqual.common.months.september.long", "Septembre", "messages", "fr");
t.add("siqual.common.months.september.short", "Sep", "messages", "fr");
t.add("siqual.common.months.october.long", "Octobre", "messages", "fr");
t.add("siqual.common.months.october.short", "Oct", "messages", "fr");
t.add("siqual.common.months.november.long", "Novembre", "messages", "fr");
t.add("siqual.common.months.november.short", "Nov", "messages", "fr");
t.add("siqual.common.months.december.long", "D\u00e9cembre", "messages", "fr");
t.add("siqual.common.months.december.short", "D\u00e9c", "messages", "fr");
t.add("siqual.common.no", "Non", "messages", "fr");
t.add("siqual.common.no_preview", "Aucun aper\u00e7u", "messages", "fr");
t.add("siqual.common.no_settings", "Aucun param\u00e8trage n'est disponible", "messages", "fr");
t.add("siqual.common.open", "Ouvrir", "messages", "fr");
t.add("siqual.common.read_more", "En savoir plus", "messages", "fr");
t.add("siqual.common.save", "Sauvegarder", "messages", "fr");
t.add("siqual.common.select_all", "Tout s\u00e9lectionner", "messages", "fr");
t.add("siqual.common.settings.analytics.code", "Code Google Analytics", "messages", "fr");
t.add("siqual.common.settings.default.error_template", "Template \u00e0 utiliser par d\u00e9faut pour les pages d'erreur", "messages", "fr");
t.add("siqual.common.settings.robots", "Robots.txt", "messages", "fr");
t.add("siqual.common.validate", "Valider", "messages", "fr");
t.add("siqual.common.validators.is_unique_email.message", "D\u00e9sol\u00e9, mais l'email saisi est d\u00e9j\u00e0 utilis\u00e9", "messages", "fr");
t.add("siqual.common.yes", "Oui", "messages", "fr");
t.add("siqual.front.common.actions.title", "Actions", "messages", "fr");
t.add("siqual.front.common.add.title_small", "Ajouter", "messages", "fr");
t.add("siqual.front.common.delete.action", "Supprimer", "messages", "fr");
t.add("siqual.front.common.edit.title_small", "Modifier", "messages", "fr");
t.add("siqual.front.common.index.title_small", "Liste", "messages", "fr");
t.add("siqual.front.common.no_result", "Aucun document ne correspond aux termes de recherche sp\u00e9cifi\u00e9s (%query%).\n\nSuggestions :\n\nV\u00e9rifiez l\u2019orthographe des termes de recherche.\nEssayez d'autres mots.\nUtilisez des mots cl\u00e9s plus g\u00e9n\u00e9raux.\nSp\u00e9cifiez un moins grand nombre de mots.\n", "messages", "fr");
t.add("siqual.front.common.notify.constraint_delete", "L'\u00e9l\u00e9ment que vous tentez de supprimer est li\u00e9 \u00e0 un ou plusieurs autres \u00e9l\u00e9ments", "messages", "fr");
t.add("siqual.front.common.notify.create", "Informations enregistr\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.front.common.notify.delete", "L'objet a bien \u00e9t\u00e9 supprim\u00e9", "messages", "fr");
t.add("siqual.front.common.notify.error", "Une erreur est survenue", "messages", "fr");
t.add("siqual.front.common.notify.form_error", "Une erreur est survenue, v\u00e9rifiez les informations saisies", "messages", "fr");
t.add("siqual.front.common.notify.success", "Informations enregistr\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.front.common.notify.update", "Informations mises \u00e0 jour avec succ\u00e8s", "messages", "fr");
t.add("siqual.front.common.pagination.total_count", "{0}|{1}%total_count% entr\u00e9e|]1,Inf]%total_count% entr\u00e9es", "messages", "fr");
t.add("siqual.front.common.tools", "Outils", "messages", "fr");
t.add("siqual.front.common.top", "Haut", "messages", "fr");
t.add("resetting.flash.success", "R\u00e9initialisation de votre mot de passe effectu\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.theme.title", "Th\u00e8me", "messages", "fr");
t.add("siqual.admin.theme.menu", "Th\u00e8me", "messages", "fr");
t.add("siqual.admin.theme.actions.reinitialiser.title", "R\u00e9initialiser les valeurs par d\u00e9faut", "messages", "fr");
t.add("siqual.admin.theme.actions.reinitialiser.content", "\u00cates-vous sure de vouloir r\u00e9initialiser les valeurs par d\u00e9faut ?", "messages", "fr");
t.add("siqual.admin.theme.fields.loginFiligraneColor", "Couleur de fond de la page de connexion", "messages", "fr");
t.add("siqual.admin.theme.fields.loginFiligraneOpacity", "Opacit\u00e9 de la couleur de fond", "messages", "fr");
t.add("siqual.admin.theme.fields.passwordFiligraneColor", "Couleur de fond de la page de mot de passe oubli\u00e9", "messages", "fr");
t.add("siqual.admin.theme.fields.passwordFiligraneOpacity", "Opacit\u00e9 de la couleur de fond", "messages", "fr");
t.add("siqual.admin.theme.fields.loginLinkColor", "Couleur des liens de la page de connexion", "messages", "fr");
t.add("siqual.admin.theme.fields.passwordLinkColor", "Couleur des liens de la page de mot de passe oubli\u00e9", "messages", "fr");
t.add("siqual.admin.theme.fields.btnDefault", "Bouton par d\u00e9faut", "messages", "fr");
t.add("siqual.admin.theme.fields.btnPrimary", "Bouton primary", "messages", "fr");
t.add("siqual.admin.theme.fields.btnSuccess", "Bouton success", "messages", "fr");
t.add("siqual.admin.theme.fields.btnWarning", "Bouton warning", "messages", "fr");
t.add("siqual.admin.theme.fields.btnDanger", "Bouton danger", "messages", "fr");
t.add("siqual.admin.theme.fields.btnInfo", "Bouton info", "messages", "fr");
t.add("siqual.admin.theme.fields.badgePrimary", "Badge primary", "messages", "fr");
t.add("siqual.admin.theme.fields.badgeSuccess", "Badge success", "messages", "fr");
t.add("siqual.admin.theme.fields.badgeWarning", "Badge warning", "messages", "fr");
t.add("siqual.admin.theme.fields.badgeDanger", "Badge danger", "messages", "fr");
t.add("siqual.admin.theme.fields.badgeInfo", "Badge info", "messages", "fr");
t.add("siqual.admin.theme.fields.backgroundColor", "Couleur de fond", "messages", "fr");
t.add("siqual.admin.theme.fields.borderColor", "Couleur de la bordure", "messages", "fr");
t.add("siqual.admin.theme.fields.color", "Couleur du texte", "messages", "fr");
t.add("siqual.admin.theme.fields.hoverBackgroundColor", "Couleur de fond au survol", "messages", "fr");
t.add("siqual.admin.theme.fields.hoverBorderColor", "Couleur de la bordure au survol", "messages", "fr");
t.add("siqual.admin.theme.fields.hoverColor", "Couleur du texte au survol", "messages", "fr");
t.add("siqual.admin.theme.fields.contentHeaderBackgroundColor", "Couleur de fond du header", "messages", "fr");
t.add("siqual.admin.theme.fields.contentHeaderColor", "Couleur du texte du header", "messages", "fr");
t.add("siqual.admin.theme.fields.contentHeaderIconColor", "Couleur des ic\u00f4nes du header", "messages", "fr");
t.add("siqual.admin.theme.fields.contentHeaderIconColorActive", "Couleur des ic\u00f4nes du header au survol", "messages", "fr");
t.add("siqual.admin.theme.fields.sideHeaderBackgroundColor", "Couleur de fond sous le logo", "messages", "fr");
t.add("siqual.admin.theme.fields.sideLeftBackgroundColor", "Couleur de fond du menu de gauche", "messages", "fr");
t.add("siqual.admin.theme.fields.sideLeftColor", "Couleur du texte du menu de gauche", "messages", "fr");
t.add("siqual.admin.theme.fields.sideLeftSelectedBackgroundColor", "Couleur de fond d'un \u00e9l\u00e9ment s\u00e9lectionn\u00e9 dans le menu", "messages", "fr");
t.add("siqual.admin.theme.fields.sideLeftSelectedColor", "Couleur du texte d'un \u00e9l\u00e9ment s\u00e9lectionn\u00e9 dans le menu", "messages", "fr");
t.add("siqual.admin.theme.fields.link", "Liens", "messages", "fr");
t.add("siqual.admin.theme.fields.login", "Page de connexion", "messages", "fr");
t.add("siqual.admin.theme.fields.password", "Page de mot de passe oubli\u00e9", "messages", "fr");
t.add("siqual.admin.theme.fields.header", "Header", "messages", "fr");
t.add("siqual.admin.theme.fields.sideLeft", "Menu de gauche", "messages", "fr");
t.add("siqual.admin.theme.fields.linkColor", "Couleur des liens", "messages", "fr");
t.add("siqual.admin.theme.fields.hoverLinkColor", "Couleur des liens au survol", "messages", "fr");
t.add("siqual.admin.theme.fields.backgroundImage", "Image de fond", "messages", "fr");
t.add("siqual.admin.theme.fields.backgroundImageMobile", "Image de fond pour smartphone", "messages", "fr");
t.add("siqual.admin.theme.fields.logo", "Logo", "messages", "fr");
t.add("siqual.admin.theme.fields.favicon", "Favicon", "messages", "fr");
t.add("siqual.admin.theme.fields.icon", "Ic\u00f4ne pour les smartphones", "messages", "fr");
t.add("siqual.admin.theme.fields.bgAsbestos", "Widget inverse", "messages", "fr");
t.add("siqual.admin.theme.fields.bgPrimary", "Widget primary", "messages", "fr");
t.add("siqual.admin.theme.fields.bgSuccess", "Widget success", "messages", "fr");
t.add("siqual.admin.theme.fields.bgWarning", "Widget warning", "messages", "fr");
t.add("siqual.admin.theme.fields.bgInfo", "Widget info", "messages", "fr");
t.add("siqual.admin.theme.fields.bgDanger", "Widget danger", "messages", "fr");
t.add("siqual.admin.theme.fields.alertInfo", "Alerte info", "messages", "fr");
t.add("siqual.admin.theme.fields.alertWarning", "Alerte warning", "messages", "fr");
t.add("siqual.admin.theme.fields.alertDanger", "Alerte danger", "messages", "fr");
t.add("siqual.admin.theme.fields.alertSuccess", "Alerte success", "messages", "fr");
t.add("siqual.admin.theme.success.update", "Modification enregistr\u00e9e avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.sitename", "Admin", "messages", "fr");
t.add("siqual.admin.alert.authentification_failed", "D\u00e9sol\u00e9, vous ne faites pas parti de la soci\u00e9t\u00e9 Siqual", "messages", "fr");
t.add("siqual.admin.alert.erreur_bitbucket", "Une erreur s'est produite lors de l'appel \u00e0 BitBucket", "messages", "fr");
t.add("siqual.admin.alert.title", "Notifications", "messages", "fr");
t.add("siqual.admin.common.actions.title", "Actions", "messages", "fr");
t.add("siqual.admin.common.add.title_small", "Cr\u00e9ation", "messages", "fr");
t.add("siqual.admin.common.add_new", "Ajouter", "messages", "fr");
t.add("siqual.admin.common.batch_action.action_applicated", "L'action demand\u00e9e a \u00e9t\u00e9 r\u00e9alis\u00e9e avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.common.batch_action.confirm", "\u00cates-vous s\u00fbr de vouloir appliquer cette action aux \u00e9l\u00e9ments s\u00e9lectionn\u00e9s ?", "messages", "fr");
t.add("siqual.admin.common.batch_action.delete", "Supprimer", "messages", "fr");
t.add("siqual.admin.common.batch_action.disable", "D\u00e9sactiver", "messages", "fr");
t.add("siqual.admin.common.batch_action.error", "Une erreur s'est produite", "messages", "fr");
t.add("siqual.admin.common.batch_action.enable", "Activer", "messages", "fr");
t.add("siqual.admin.common.batch_action.export", "Exporter", "messages", "fr");
t.add("siqual.admin.common.batch_action.invalid_csrf_token", "Jeton CSRF invalide", "messages", "fr");
t.add("siqual.admin.common.batch_action.no_actions_selected", "Merci de s\u00e9lectionner une action", "messages", "fr");
t.add("siqual.admin.common.batch_action.no_objects_selected", "Merci de s\u00e9lectionner des \u00e9lements \u00e0 traiter", "messages", "fr");
t.add("siqual.admin.common.batch_action.no_good_method", "Merci de passer par le formulaire appropri\u00e9 pour \u00e9ffectuer cette action", "messages", "fr");
t.add("siqual.admin.common.batch_action.publish", "Publier", "messages", "fr");
t.add("siqual.admin.common.batch_action.reinitPassword", "R\u00e9initialiser le mot de passe", "messages", "fr");
t.add("siqual.admin.common.batch_action.select_action", "S\u00e9lectionner une action", "messages", "fr");
t.add("siqual.admin.common.batch_action.submit", "Appliquer aux \u00e9l\u00e9ments s\u00e9lectionn\u00e9s", "messages", "fr");
t.add("siqual.admin.common.batch_action.unpublish", "D\u00e9publier", "messages", "fr");
t.add("siqual.admin.common.cancel", "Annuler", "messages", "fr");
t.add("siqual.admin.common.close", "Fermer", "messages", "fr");
t.add("siqual.admin.common.configuration.title_small", "Configuration", "messages", "fr");
t.add("siqual.admin.common.confirm.modal.body", "\u00cates-vous s\u00fbr de vouloir effectuer cette action ? <br> Celle-ci est irr\u00e9versible.", "messages", "fr");
t.add("siqual.admin.common.confirm.modal.title", "Veuillez confirmer", "messages", "fr");
t.add("siqual.admin.common.confirm.title", "Confirmer", "messages", "fr");
t.add("siqual.admin.common.create_password.flashes.success", "Un email vient d'\u00eatre envoy\u00e9 \u00e0 l'utilisateur.", "messages", "fr");
t.add("siqual.admin.common.create_password.flashes.error", "L'email de l'utilisateur n'est pas valide.", "messages", "fr");
t.add("siqual.admin.common.delete.action", "Supprimer", "messages", "fr");
t.add("siqual.admin.common.delete.modal.body", "\u00cates-vous s\u00fbr de vouloir effectuer cette action ? <br> Celle-ci est irr\u00e9versible.", "messages", "fr");
t.add("siqual.admin.common.delete.modal.title", "Confirmation de suppression", "messages", "fr");
t.add("siqual.admin.common.edit.action", "Modifier", "messages", "fr");
t.add("siqual.admin.common.edit.title_small", "\u00c9dition", "messages", "fr");
t.add("siqual.admin.common.export.action", "Exporter", "messages", "fr");
t.add("siqual.admin.common.fields.createdAt", "Date de cr\u00e9ation", "messages", "fr");
t.add("siqual.admin.common.fields.deletedAt", "Date de suppression", "messages", "fr");
t.add("siqual.admin.common.fields.updatedAt", "Date de mise \u00e0 jour", "messages", "fr");
t.add("siqual.admin.common.filemanager", "Gestionnaire de fichiers", "messages", "fr");
t.add("siqual.admin.common.go_to_website", "Acc\u00e9der au site", "messages", "fr");
t.add("siqual.admin.common.import.action", "Importer", "messages", "fr");
t.add("siqual.admin.common.index.title_small", "Liste", "messages", "fr");
t.add("siqual.admin.common.max_per_page.label", "Nombre d'\u00e9l\u00e9ments par page :", "messages", "fr");
t.add("siqual.admin.common.nb_per_page", "Nombre par page", "messages", "fr");
t.add("siqual.admin.common.no_result", "Aucun r\u00e9sultat", "messages", "fr");
t.add("siqual.admin.common.no_results_match", "Aucun r\u00e9sultat pour", "messages", "fr");
t.add("siqual.admin.common.notify.constraint_delete", "L'\u00e9l\u00e9ment que vous tentez de supprimer est li\u00e9 \u00e0 un ou plusieurs autres \u00e9l\u00e9ments", "messages", "fr");
t.add("siqual.admin.common.notify.create", "Informations enregistr\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.common.notify.delete", "L'objet a bien \u00e9t\u00e9 supprim\u00e9", "messages", "fr");
t.add("siqual.admin.common.notify.error", "Une erreur est survenue", "messages", "fr");
t.add("siqual.admin.common.notify.form_error", "Une erreur est survenue, v\u00e9rifiez les informations saisies", "messages", "fr");
t.add("siqual.admin.common.notify.success", "Informations enregistr\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.common.notify.update", "Informations mises \u00e0 jour avec succ\u00e8s", "messages", "fr");
t.add("siqual.admin.common.pagination.total_count", "{0}|{1}%total_count% entr\u00e9e|]1,Inf]%total_count% entr\u00e9es", "messages", "fr");
t.add("siqual.admin.common.pdf.action", "PDF", "messages", "fr");
t.add("siqual.admin.common.preview.title_small", "Aper\u00e7u", "messages", "fr");
t.add("siqual.admin.common.print", "Imprimer", "messages", "fr");
t.add("siqual.admin.common.publish_unpublish.publish", "Publication effectu\u00e9e", "messages", "fr");
t.add("siqual.admin.common.publish_unpublish.unpublish", "D\u00e9publication effectu\u00e9e", "messages", "fr");
t.add("siqual.admin.common.publish_unpublish.error", "Impossible de modifier le statut de publication. Une erreur est survenue.", "messages", "fr");
t.add("siqual.admin.common.reset", "R\u00e9initialiser", "messages", "fr");
t.add("siqual.admin.common.resetting.flashes.success", "Si l\u2019e-mail indiqu\u00e9 correspond \u00e0 un compte, vous recevrez un lien contenant la proc\u00e9dure \u00e0 suivre pour r\u00e9initialiser votre mot de passe", "messages", "fr");
t.add("siqual.admin.common.return", "Retour", "messages", "fr");
t.add("siqual.admin.common.save", "Sauvegarder", "messages", "fr");
t.add("siqual.admin.common.save_and_add", "Sauvegarder et ajouter", "messages", "fr");
t.add("siqual.admin.common.save_and_list", "Sauvegarder et revenir \u00e0 la liste", "messages", "fr");
t.add("siqual.admin.common.search", "Rechercher", "messages", "fr");
t.add("siqual.admin.common.select", "Selectionner", "messages", "fr");
t.add("siqual.admin.common.select_an_option", "Selectionner une option", "messages", "fr");
t.add("siqual.admin.common.select_some_options", "Selectionner des options", "messages", "fr");
t.add("siqual.admin.common.send", "Envoyer", "messages", "fr");
t.add("siqual.admin.common.show.action", "Voir", "messages", "fr");
t.add("siqual.admin.common.show.title_small", "Consultation", "messages", "fr");
t.add("siqual.admin.common.sort.action", "Organiser", "messages", "fr");
t.add("siqual.admin.common.sort.submit", "Sauvegarder l'ordre", "messages", "fr");
t.add("siqual.admin.common.sort.title_small", "Organisation", "messages", "fr");
t.add("siqual.admin.common.tools", "Outils", "messages", "fr");
t.add("siqual.admin.config.activate_registration", "Activer l'inscription au back office", "messages", "fr");
t.add("siqual.admin.config.background", "Image de fond", "messages", "fr");
t.add("siqual.admin.config.background_mobile", "Image de fond sur les smartphones", "messages", "fr");
t.add("siqual.admin.config.default_group", "Groupe par d\u00e9faut", "messages", "fr");
t.add("siqual.admin.config.favicon", "Favicon du back office", "messages", "fr");
t.add("siqual.admin.config.group_name", "Back office", "messages", "fr");
t.add("siqual.admin.config.logo", "Logo", "messages", "fr");
t.add("siqual.admin.config.meta_title", "Balise meta title du back office", "messages", "fr");
t.add("siqual.admin.config.touch_icon", "Icone pour smartphones\/tablettes", "messages", "fr");
t.add("siqual.admin.config.welcome_email_content", "Contenu de l'email de bienvenue", "messages", "fr");
t.add("siqual.admin.config.welcome_email_subject", "Sujet de l'email de bienvenue", "messages", "fr");
t.add("siqual.admin.core.dashboard", "Tableau de bord", "messages", "fr");
t.add("siqual.admin.core.title", "Panel d'administration", "messages", "fr");
t.add("siqual.admin.create_password.email.content", "Cher %username%,<br \/>\n<br \/>\nVotre mot de passe a \u00e9t\u00e9 r\u00e9initialis\u00e9. Pour d\u00e9finir un nouveau mot de passe, merci de <a href=\"%confirmationUrl%\" class=\"btn btn-primary\">Cliquer ici<\/a>\n", "messages", "fr");
t.add("siqual.admin.create_password.email.subject", "R\u00e9initialisation de votre mot de passe", "messages", "fr");
t.add("siqual.admin.create_password.send_email", "Envoyer les instructions de r\u00e9initialisation de mot de passe", "messages", "fr");
t.add("siqual.admin.create_password.send_email_short", "R\u00e9initialisation de mot de passe", "messages", "fr");
t.add("siqual.admin.create_password.send_welcome_email", "Envoyer l'email de bienvenue", "messages", "fr");
t.add("siqual.admin.create_password.welcome_email.content", "Cher %username%,<br \/>\n<br \/>\nVotre compte a \u00e9t\u00e9 cr\u00e9\u00e9. Pour d\u00e9finir votre mot de passe, merci de <a href=\"%confirmationUrl%\" class=\"btn btn-primary\">Cliquer ici<\/a>\n", "messages", "fr");
t.add("siqual.admin.create_password.welcome_email.subject", "Bienvenue", "messages", "fr");
t.add("siqual.admin.link.forgot_password", "Mot de passe oubli\u00e9 ?", "messages", "fr");
t.add("siqual.admin.login.already_subscribed", "D\u00e9j\u00e0 inscrit ?", "messages", "fr");
t.add("siqual.admin.login.header", "Connexion", "messages", "fr");
t.add("siqual.admin.login.remember_me", "Se souvenir de moi", "messages", "fr");
t.add("siqual.admin.login.submit_form", "Connexion", "messages", "fr");
t.add("siqual.admin.menu.accounting.title", " Comptabilit\u00e9", "messages", "fr");
t.add("siqual.admin.menu.catalogue.title", " Catalogue", "messages", "fr");
t.add("siqual.admin.menu.contacts.title", " Contacts", "messages", "fr");
t.add("siqual.admin.menu.content.title", " Contenu", "messages", "fr");
t.add("siqual.admin.menu.dashboard.title", " Tableau de bord", "messages", "fr");
t.add("siqual.admin.menu.delivery.title", " Livraisons", "messages", "fr");
t.add("siqual.admin.menu.delivery_return.title", " Retours", "messages", "fr");
t.add("siqual.admin.menu.gateway.title", " Passerelles EDI", "messages", "fr");
t.add("siqual.admin.menu.hidden", " Autres", "messages", "fr");
t.add("siqual.admin.menu.identity.logout", " D\u00e9connexion", "messages", "fr");
t.add("siqual.admin.menu.log.title", " Logs", "messages", "fr");
t.add("siqual.admin.menu.sidebar", " Menu", "messages", "fr");
t.add("siqual.admin.menu.tools", " Param\u00e8tres", "messages", "fr");
t.add("siqual.admin.register.fields.firstname", "Pr\u00e9nom", "messages", "fr");
t.add("siqual.admin.register.fields.lastname", "Nom", "messages", "fr");
t.add("siqual.admin.register.header", "Inscription", "messages", "fr");
t.add("siqual.admin.register.no_account", "Pas de compte ?", "messages", "fr");
t.add("siqual.admin.register.submit_form", "S'inscrire", "messages", "fr");
t.add("siqual.admin.resetting.check_email", "Un email contenant la proc\u00e9dure \u00e0 suivre pour finir le processus de r\u00e9initialisation de votre mot de passe vient de vous \u00eatre envoy\u00e9 \u00e0 l'adresse suivante : <b>\"%email%\"<\/b>.\n&nbsp;\nNB : Merci de v\u00e9rifier vos spams si vous ne recevez pas cet email.\n", "messages", "fr");
t.add("siqual.admin.resetting.email.content", "Cher %username%,<br \/>\n<br \/>\nPour r\u00e9initialiser votre mot de passe, merci de <a href=\"%confirmationUrl%\" class=\"btn btn-primary\">Cliquer ici<\/a>\n", "messages", "fr");
t.add("siqual.admin.resetting.email.subject", "R\u00e9initialisation de votre mot de passe", "messages", "fr");
t.add("siqual.admin.resetting.invalid_username", "Cette adresse email n'existe pas", "messages", "fr");
t.add("siqual.admin.resetting.password_already_requested", "D\u00e9sol\u00e9, mais vous avez d\u00e9j\u00e0 une demande en cours", "messages", "fr");
t.add("siqual.admin.resetting.request.back", "Retour", "messages", "fr");
t.add("siqual.admin.resetting.request.cancel", "Annuler", "messages", "fr");
t.add("siqual.admin.resetting.request.info", "Saisissez votre e-mail afin de r\u00e9initialiser votre mot de passe.", "messages", "fr");
t.add("siqual.admin.resetting.request.submit", "Envoyer", "messages", "fr");
t.add("siqual.admin.resetting.reset.submit", "Sauvegarder", "messages", "fr");
t.add("siqual.admin.resetting.reset.title", "Merci de saisir un nouveau mot de passe", "messages", "fr");
t.add("siqual.admin.tinyMCE.plugins.line1", "btn advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker", "messages", "fr");
t.add("siqual.admin.tinyMCE.plugins.line2", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking", "messages", "fr");
t.add("siqual.admin.tinyMCE.plugins.line3", "table contextmenu directionality emoticons template textcolor paste textcolor responsivefilemanager fontawesome icomoon siqualfont", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.lead", "Mise en avant", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style1", "Style 1", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style2", "Style 2", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style3", "Style 3", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style4", "Style 4", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style5", "Style 5", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style6", "Style 6", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style7", "Style 7", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style8", "Style 8", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style9", "Style 9", "messages", "fr");
t.add("siqual.admin.tinyMCE.styles.style10", "Style 10", "messages", "fr");
t.add("siqual.admin.tinyMCE.toolbars.toolbar1", " undo redo | styleselect bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | formatselect | subscript superscript | fontawesome icomoon siqualfont ", "messages", "fr");
t.add("siqual.admin.tinyMCE.toolbars.toolbar2", "cut copy paste | bullist numlist | table | outdent indent blockquote hr | link unlink btn image media | visualblocks spellchecker removeformat fullscreen", "messages", "fr");
t.add("siqual.admin.tinyMCE.toolbars.toolbar3", "", "messages", "fr");
t.add("siqual.customer_area.errors.account_expired", "Le compte est expir\u00e9", "messages", "fr");
t.add("siqual.customer_area.errors.account_not_activated", "Le compte n'est pas activ\u00e9", "messages", "fr");
t.add("siqual.customer_area.errors.login_password_incorrect", "Identifiant ou mot de passe incorrect", "messages", "fr");
t.add("siqual.customer_area.errors.token_expired", "Le lien est expir\u00e9. Veuillez recommencer la proc\u00e9dure de r\u00e9initialisation de mot de passe", "messages", "fr");
t.add("registration", null, "messages", "fr");
t.add("siqual.user.flash.user_created", "Votre compte a \u00e9t\u00e9 cr\u00e9\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("siqual.user.admin.tools.menu.login_history", "Historique de connexion", "messages", "fr");
t.add("siqual.user.admin.roles.consultation", "<i class=\"icon-list-ul\"><\/i><span class=\"hidden-xs\"> Consultation<\/span>", "messages", "fr");
t.add("siqual.user.admin.roles.ajout", "<i class=\"icon-plus\"><\/i><span class=\"hidden-xs\">  Ajout<\/span>", "messages", "fr");
t.add("siqual.user.admin.roles.modification", "<i class=\"icon-edit\"><\/i><span class=\"hidden-xs\">  Modification<\/span>", "messages", "fr");
t.add("siqual.user.admin.roles.suppression", "<i class=\"icon-trash\"><\/i><span class=\"hidden-xs\">  Suppression<\/span>", "messages", "fr");
t.add("siqual.user.admin.roles.autre", "<i class=\"icon-ellipsis-h\"><\/i><span class=\"hidden-xs\">  Autre<\/span>", "messages", "fr");
t.add("siqual.user.admin.log_as.action", "Se connecter", "messages", "fr");
t.add("siqual.user.admin.log_as.title", "Se connecter en tant que", "messages", "fr");
t.add("siqual.user.admin.menu.configs.groups", "Groupes d'utilisateurs", "messages", "fr");
t.add("siqual.user.admin.menu.configs.users", "Utilisateurs", "messages", "fr");
t.add("siqual.user.admin.menu.configs.login_history", "Historique de connexion", "messages", "fr");
t.add("siqual.user.admin.menu.identity.profil", "Mon profil", "messages", "fr");
t.add("siqual.user.admin.user.filters.firstname", "Pr\u00e9nom", "messages", "fr");
t.add("siqual.user.admin.user.filters.lastname", "Nom", "messages", "fr");
t.add("siqual.user.admin.user.filters.email", "Email", "messages", "fr");
t.add("siqual.user.admin.user.filters.enabled.label", "Active ?", "messages", "fr");
t.add("siqual.user.admin.user.filters.enabled.yes_no", "Oui ou non", "messages", "fr");
t.add("siqual.user.admin.user.filters.enabled.no", "Non", "messages", "fr");
t.add("siqual.user.admin.user.filters.enabled.yes", "Oui", "messages", "fr");
t.add("siqual.user.admin.user.field.email.label", "E-mail", "messages", "fr");
t.add("siqual.user.admin.user.field.enabled.label", "Activ\u00e9 ?", "messages", "fr");
t.add("siqual.user.admin.user.field.lastLogin.label", "Derni\u00e8re connexion", "messages", "fr");
t.add("siqual.user.admin.user.field.expiresAt.label", "Date d'expiration", "messages", "fr");
t.add("siqual.user.admin.user.field.expiresAt.placeholder", "Expire le", "messages", "fr");
t.add("siqual.user.admin.user.field.firstName.label", "Pr\u00e9nom", "messages", "fr");
t.add("siqual.user.admin.user.field.groups.label", "Groupes", "messages", "fr");
t.add("siqual.user.admin.user.field.lastName.label", "Nom", "messages", "fr");
t.add("siqual.user.admin.user.field.userName.label", "Nom d'utilisateur", "messages", "fr");
t.add("siqual.user.admin.user.field.password.label", "Mot de passe", "messages", "fr");
t.add("siqual.user.admin.user.field.password.secondOption.label", "Mot de passe (confirmation)", "messages", "fr");
t.add("siqual.user.admin.user.field.password.secondOption.placeholder", "Confirmation", "messages", "fr");
t.add("siqual.user.admin.user.index.box.add_new", "Ajouter un utilisateur", "messages", "fr");
t.add("siqual.user.admin.user.index.box.nb_per_page", "par page", "messages", "fr");
t.add("siqual.user.admin.user.index.box.tools", "R\u00e9glages", "messages", "fr");
t.add("siqual.user.admin.user.index.box.title", "Utilisateurs", "messages", "fr");
t.add("siqual.user.admin.user.title", "Utilisateurs", "messages", "fr");
t.add("siqual.user.admin.form.main_permission", "Permission de base", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.title", "Mon profil", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.connexion.title", "Connexion", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.connexion.email", "Email", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.connexion.password", "Mot de passe", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.connexion.password_confirmation", "Mot de passe (confirmation)", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.profil.title", "Profil", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.profil.prenom", "Pr\u00e9nom", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.profil.nom", "Nom", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.permissions.title", "Permissions", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.permissions.group", "Groupes", "messages", "fr");
t.add("siqual.user.admin.form.profil.edit.tabs.button_submit", "Sauvegarder", "messages", "fr");
t.add("siqual.user.admin.form.group.edit.tabs.informations.title", "Informations g\u00e9n\u00e9rales", "messages", "fr");
t.add("siqual.user.admin.form.group.edit.tabs.permissions.title", "Permissions", "messages", "fr");
t.add("siqual.user.admin.form.user.edit.tabs.informations.title", "Informations g\u00e9n\u00e9rales", "messages", "fr");
t.add("siqual.user.admin.form.user.edit.tabs.permissions.title", "Permissions", "messages", "fr");
t.add("siqual.user.admin.profil.title", "Edition de mon profil", "messages", "fr");
t.add("siqual.user.admin.profil.success", "Informations enregistr\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.group.admin.actions.clone", "Dupliquer", "messages", "fr");
t.add("siqual.group.admin.fields.name", "Nom", "messages", "fr");
t.add("siqual.group.admin.fields.description", "Description", "messages", "fr");
t.add("siqual.group.admin.fields.referenceur", "R\u00e9f\u00e9renceur ?", "messages", "fr");
t.add("siqual.group.admin.fields.link", "Lien", "messages", "fr");
t.add("siqual.group.admin.fields.image", "Image", "messages", "fr");
t.add("siqual.group.admin.fields.createdAt", "Date de cr\u00e9ation", "messages", "fr");
t.add("siqual.group.admin.index.title", "Groupe", "messages", "fr");
t.add("siqual.group.admin.index.box.add_new", "Ajouter un groupe", "messages", "fr");
t.add("siqual.group.admin.index.box.title", "Groupe", "messages", "fr");
t.add("siqual.group.admin.menu.title", "Groupes", "messages", "fr");
t.add("siqual.group.admin.notify.delete", "Le groupe a bien \u00e9t\u00e9 supprim\u00e9", "messages", "fr");
t.add("siqual.group.admin.title", "Groupes", "messages", "fr");
t.add("siqual.login_history.admin.fields.username", "Nom d'utilisateur", "messages", "fr");
t.add("siqual.login_history.admin.fields.application", "Application", "messages", "fr");
t.add("siqual.login_history.admin.fields.ip", "Adresse IP", "messages", "fr");
t.add("siqual.login_history.admin.fields.state", "Etat", "messages", "fr");
t.add("siqual.login_history.admin.fields.createdAt", "Date", "messages", "fr");
t.add("siqual.login_history.admin.index.title", "Historique de connexion", "messages", "fr");
t.add("siqual.login_history.admin.index.box.title", "Historique de connexion", "messages", "fr");
t.add("siqual.login_history.admin.title", "Historique de connexion", "messages", "fr");
t.add("siqual.bootstrap.form.select_file", "Parcourir...", "messages", "fr");
t.add("siqual.bootstrap.form.change", "Changer", "messages", "fr");
t.add("siqual.bootstrap.form.remove", "Supprimer", "messages", "fr");
t.add("siqual.bootstrap.form.switch.on", "Oui", "messages", "fr");
t.add("siqual.bootstrap.form.switch.off", "Non", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.datetime_long", "dd MMMM Y - HH:mm", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.datetime_short", "dd MMM Y - HH:mm", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.datetime", "dd\/MM\/Y - HH:mm", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.date_long", "dd MMMM Y", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.date_short", "dd MMM Y", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.date", "dd\/MM\/Y", "messages", "fr");
t.add("siqual.bootstrap.form.datetime_twig.time", "HH:mm", "messages", "fr");
t.add("siqual.bootstrap.form.modal_choice.choose", "Choisir", "messages", "fr");
t.add("siqual.bootstrap.form.modal_choice.no_result", "Aucun r\u00e9sultat", "messages", "fr");
t.add("siqual.bootstrap.form.recaptcha.sitekey", "Recaptcha site key", "messages", "fr");
t.add("siqual.bootstrap.form.recaptcha.secretkey", "Recaptcha secret key", "messages", "fr");
t.add("siqual.bootstrap.confirm.modal.title", "Veuillez confirmer", "messages", "fr");
t.add("siqual.bootstrap.confirm.modal.body", "<p class=\"lead\">\u00cates-vous s\u00fbr de vouloir effectuer cette action ?<\/p>", "messages", "fr");
t.add("siqual.bootstrap.confirm.modal.actions.confirm", "Confirmer", "messages", "fr");
t.add("siqual.bootstrap.confirm.modal.actions.cancel", "Annuler", "messages", "fr");
t.add("siqual.bootstrap.delete.modal.title", "Confirmation de suppression", "messages", "fr");
t.add("siqual.bootstrap.delete.modal.body", "\u00cates-vous s\u00fbr de vouloir effectuer cette action ?", "messages", "fr");
t.add("siqual.bootstrap.delete.modal.actions.delete", "Supprimer", "messages", "fr");
t.add("siqual.bootstrap.delete.modal.actions.cancel", "Annuler", "messages", "fr");
t.add("siqual.config.admin.config.form.type.checkbox", "Case \u00e0 cocher", "messages", "fr");
t.add("siqual.config.admin.config.form.type.country", "Liste unique de pays", "messages", "fr");
t.add("siqual.config.admin.config.form.type.currency", "Choix d'une devise", "messages", "fr");
t.add("siqual.config.admin.config.form.type.date", "Choix d'une date", "messages", "fr");
t.add("siqual.config.admin.config.form.type.datetime", "Choix d'une date et d'une heure", "messages", "fr");
t.add("siqual.config.admin.config.form.type.editarea", "Code", "messages", "fr");
t.add("siqual.config.admin.config.form.type.email", "Email", "messages", "fr");
t.add("siqual.config.admin.config.form.type.entity", "Choix dans une liste deroulante d'un model sp\u00e9cifique", "messages", "fr");
t.add("siqual.config.admin.config.form.type.hidden", "Champs cach\u00e9", "messages", "fr");
t.add("siqual.config.admin.config.form.type.input", "Champs texte", "messages", "fr");
t.add("siqual.config.admin.config.form.type.model", "Choix dans une liste deroulante d'un model sp\u00e9cifique", "messages", "fr");
t.add("siqual.config.admin.config.form.type.password", "Mot de passe", "messages", "fr");
t.add("siqual.config.admin.config.form.type.radio", "Bouton radio", "messages", "fr");
t.add("siqual.config.admin.config.form.type.recaptcha", "ReCaptcha", "messages", "fr");
t.add("siqual.config.admin.config.form.type.richtext", "Bloc de texte WYSIWYG", "messages", "fr");
t.add("siqual.config.admin.config.form.type.select", "Choix unique dans une liste d\u00e9roulante", "messages", "fr");
t.add("siqual.config.admin.config.form.type.tel", "T\u00e9l\u00e9phone", "messages", "fr");
t.add("siqual.config.admin.config.form.type.textarea", "Bloc de texte", "messages", "fr");
t.add("siqual.config.admin.config.form.type.time", "Choix d'une heure", "messages", "fr");
t.add("siqual.config.admin.config.form.type.upload", "Mise en ligne d'un fichier", "messages", "fr");
t.add("siqual.config.admin.config.form.type.yesno", "Oui\/Non", "messages", "fr");
t.add("siqual.config.admin.config.index.title", "Configuration\/R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.fields.empty_value.group", "Veuillez s\u00e9lectionner un groupe", "messages", "fr");
t.add("siqual.config.admin.fields.name.groupe", "Groupe", "messages", "fr");
t.add("siqual.config.admin.fields.name.help", "Aide", "messages", "fr");
t.add("siqual.config.admin.fields.name.options", "Options", "messages", "fr");
t.add("siqual.config.admin.fields.name.role", "Permission", "messages", "fr");
t.add("siqual.config.admin.fields.name.slug", "Identifiant", "messages", "fr");
t.add("siqual.config.admin.fields.name.title", "Nom", "messages", "fr");
t.add("siqual.config.admin.fields.name.type_de_champs", "Type de champs", "messages", "fr");
t.add("siqual.config.admin.fields.name.valeur", "Valeur", "messages", "fr");
t.add("siqual.config.admin.fields.name.valeur_par_defaut", "Valeur par d\u00e9faut", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.defaultValue", "Valeur par d\u00e9faut", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.id", "Slug", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.group", "S\u00e9lectionner un groupe", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.help", "Aide", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.name", "Nom", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.options", "Options", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.role", "Permission", "messages", "fr");
t.add("siqual.config.admin.fields.placeholder.value", "Valeur", "messages", "fr");
t.add("siqual.config.admin.form.list.title", "Configuration\/R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.form.list.title_small", "Configuration g\u00e9n\u00e9rale", "messages", "fr");
t.add("siqual.config.admin.index.box.add_new", "Ajouter", "messages", "fr");
t.add("siqual.config.admin.index.box.title", "Configuration\/R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.index.box.title_edit", "Edition", "messages", "fr");
t.add("siqual.config.admin.index.delete_button", "Supprimer", "messages", "fr");
t.add("siqual.config.admin.index.restore_all_default_values_action", "Restaurer toutes les valeurs d'usine", "messages", "fr");
t.add("siqual.config.admin.index.restore_default_value_button", "Restaurer la valeur d'usine", "messages", "fr");
t.add("siqual.config.admin.index.edit_button", "Modifier", "messages", "fr");
t.add("siqual.config.admin.index.tools", "R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.index.save_button", "Sauvegarder", "messages", "fr");
t.add("siqual.config.admin.menu.tools.general", "Configuration\/R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.menu.tools.group", "Groupes de configuration", "messages", "fr");
t.add("siqual.config.admin.no_settings_defined", "Aucune valeur de configuration d\u00e9finie.", "messages", "fr");
t.add("siqual.config.admin.notify.delete", "Valeur de configuration supprim\u00e9e avec succ\u00e8s.", "messages", "fr");
t.add("siqual.config.admin.notify.setting_restored", "La valeur d'usine a bien \u00e9t\u00e9 restaur\u00e9e", "messages", "fr");
t.add("siqual.config.admin.notify.settings_restored", "Les valeurs d'usine ont bien \u00e9t\u00e9 restaur\u00e9es", "messages", "fr");
t.add("siqual.config.admin.modify_settings", "appliquer", "messages", "fr");
t.add("siqual.config.admin.settings_changed", "Les valeurs de configurations ont \u00e9t\u00e9 appliqu\u00e9es avec succ\u00e8s", "messages", "fr");
t.add("siqual.config.admin.tabs_general", "Liste des valeurs de configuration disponibles", "messages", "fr");
t.add("siqual.config.admin.tab.label", "Configuration\/R\u00e9glages", "messages", "fr");
t.add("siqual.config.admin.title", "Configuration\/R\u00e8glages", "messages", "fr");
t.add("siqual.config_group.admin.fields.icon.label", "Ic\u00f4ne", "messages", "fr");
t.add("siqual.config_group.admin.fields.icon.placeholder", "S\u00e9lectionner une ic\u00f4ne", "messages", "fr");
t.add("siqual.config_group.admin.fields.name.label", "Nom", "messages", "fr");
t.add("siqual.config_group.admin.index.box.add_new", "Ajouter un groupe", "messages", "fr");
t.add("siqual.config_group.admin.index.box.title", "Groupes", "messages", "fr");
t.add("siqual.config_group.admin.index.show_config_button", "Voir les configurations", "messages", "fr");
t.add("siqual.config_group.admin.index.title", "Groupes", "messages", "fr");
t.add("siqual.config_group.admin.modal.cancel", "Annuler", "messages", "fr");
t.add("siqual.config_group.admin.modal.label", "Ajouter un groupe", "messages", "fr");
t.add("siqual.config_group.admin.modal.success", "Ajout r\u00e9ussi", "messages", "fr");
t.add("siqual.config_group.admin.modal.submit", "Valider", "messages", "fr");
t.add("siqual.config_group.admin.tab.label", "Groupes", "messages", "fr");
t.add("siqual.mail.admin.config.desactiver_notification", "D\u00e9sactiver la copie cach\u00e9e \u00e0 notification@siqual.fr", "messages", "fr");
t.add("siqual.mail.admin.config.dkim_private_key", "Clef priv\u00e9e DKIM", "messages", "fr");
t.add("siqual.mail.admin.config.footer", "Footer", "messages", "fr");
t.add("siqual.mail.admin.config.group", "Emails", "messages", "fr");
t.add("siqual.mail.admin.config.header", "Header", "messages", "fr");
t.add("siqual.mail.admin.config.noreply", "Adresse email \"noreply\"", "messages", "fr");
t.add("siqual.mail.admin.config.subject_prefix", "Ajouter un pr\u00e9fix aux sujets des emails envoy\u00e9s", "messages", "fr");
t.add("siqual.mail.admin.emptySpool.sent", "%nb% emails envoy\u00e9s", "messages", "fr");
t.add("siqual.mail.admin.emptySpool.spoolDisabled", "Pas d'emails envoy\u00e9s car le spool est d\u00e9sactiv\u00e9", "messages", "fr");
t.add("siqual.mail.admin.fields.bcc", "Destinataires cach\u00e9s", "messages", "fr");
t.add("siqual.mail.admin.fields.content", "Contenu", "messages", "fr");
t.add("siqual.mail.admin.fields.createdAt", "Date de cr\u00e9ation", "messages", "fr");
t.add("siqual.mail.admin.fields.recipients", "Destinataires", "messages", "fr");
t.add("siqual.mail.admin.fields.sender", "Emetteur", "messages", "fr");
t.add("siqual.mail.admin.fields.subject", "Sujet", "messages", "fr");
t.add("siqual.mail.admin.filter.content.label", "Mots cl\u00e9fs", "messages", "fr");
t.add("siqual.mail.admin.filter.content.help", "S\u00e9parez les mots cl\u00e9fs par des virgules", "messages", "fr");
t.add("siqual.mail.admin.index.box.title", "Emails", "messages", "fr");
t.add("siqual.mail.admin.menu.title", "Emails", "messages", "fr");
t.add("siqual.mail.admin.title", "Emails", "messages", "fr");
t.add("siqual.mail.show.fields.attachments", "Pi\u00e8ces-jointes", "messages", "fr");
t.add("siqual.mail.show.fields.bcc", "Bcc", "messages", "fr");
t.add("siqual.mail.show.fields.cc", "Cc", "messages", "fr");
t.add("siqual.mail.show.fields.date", "Date", "messages", "fr");
t.add("siqual.mail.show.fields.nb_attachment", "{0,1}%number% fichier, %size%|]1, Inf]%number% fichiers, %size%", "messages", "fr");
t.add("siqual.mail.show.fields.sender", "De", "messages", "fr");
t.add("siqual.mail.show.fields.recipients", "\u00c0", "messages", "fr");
t.add("siqual.media.admin.success.cache", "Le cache a bien \u00e9t\u00e9 vid\u00e9", "messages", "fr");
t.add("siqual.media.admin.index.box.title", "M\u00e9dia", "messages", "fr");
t.add("siqual.media.admin.index.title", "M\u00e9dia", "messages", "fr");
t.add("siqual.media.admin.menu.title", "M\u00e9dia", "messages", "fr");
t.add("siqual.media.admin.title", "M\u00e9dia", "messages", "fr");
t.add("siqual.media.admin.tools.clear_cache", "Vider le cache", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport.indicateurEnvoiRapport", "Indicateur Envoi Rapport", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport.indicateurRapportReconnaissance", "Indicateur Rapport Reconnaissance", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport.title", "Statistiques : respect des d\u00e9lais", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.indicateurEnvoiRapport", "Indicateur Envoi Rapport", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.indicateurRapportReconnaissance", "Indicateur Rapport Reconnaissance", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.table.afficherCacher", "Cliquer pour afficher\/cacher la liste des dossiers", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.table.dossier", "Dossier", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.table.statut", "Statut", "messages", "fr");
t.add("app.admin.dashboard.widget.statistiques_delai_rapport_user.title", "Statistiques : respect de vos d\u00e9lais", "messages", "fr");
t.add("app.config.kanban.title", "Kanban", "messages", "fr");
t.add("app.config.kanban.rapport", "D\u00e9lai de rapport (jours)", "messages", "fr");
t.add("app.config.kanban.rapport_marge_secu", "Marge de s\u00e9curit\u00e9 de rapport (jours)", "messages", "fr");
t.add("app.config.kanban.rapport_reconnaissance", "D\u00e9lai de rapport de reconnaissance (jours)", "messages", "fr");
t.add("app.config.kanban.rapport_reconnaissance_marge_secu", "Marge de s\u00e9curit\u00e9 de rapport de reconnaissance (jours)", "messages", "fr");
t.add("app.config.outlook.title", "Connexion outlook", "messages", "fr");
t.add("app.config.outlook.login", "Identifiant", "messages", "fr");
t.add("app.config.outlook.passe", "Passe", "messages", "fr");
t.add("app.common.new", "Nouveau", "messages", "fr");
t.add("app.common.actions.add", "Ajouter", "messages", "fr");
t.add("app.menu.administration", "Administration", "messages", "fr");
t.add("app.menu.annuaire", "Annuaire", "messages", "fr");
t.add("app.menu.dossier", "Dossiers", "messages", "fr");
t.add("app.menu.email", "Classement des emails", "messages", "fr");
t.add("app.menu.note_de_frais", "Notes de frais", "messages", "fr");
t.add("app.menu.tableau_synoptique", "Tableau synoptique", "messages", "fr");
t.add("app.menu.tache_commune", "T\u00e2ches communes secr\u00e9tariat", "messages", "fr");
t.add("app.menu.tache_personnelle", "T\u00e2ches personnelles", "messages", "fr");
t.add("app.action_ticket.admin.title", "Actions sur tickets", "messages", "fr");
t.add("app.action_ticket.admin.menu", "Actions sur tickets", "messages", "fr");
t.add("app.action_ticket.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.cabinet.label", "Cabinet d'avocat", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.cabinet.placeholder", "S\u00e9lectionner un cabinet", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.contact.label", "Contact avocat", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.contact.placeholder", "S\u00e9lectionner un contact", "messages", "fr");
t.add("app.cabinet_avocat.admin.fields.reference.label", "R\u00e9f\u00e9rences", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.cabinet.label", "Cabinet d'expertise", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.cabinet.placeholder", "S\u00e9lectionner un cabinet", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.contact.label", "Contact expert", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.contact.placeholder", "S\u00e9lectionner un contact", "messages", "fr");
t.add("app.cabinet_expertise.admin.fields.reference.label", "R\u00e9f\u00e9rences", "messages", "fr");
t.add("app.contact.admin.title", "Contacts", "messages", "fr");
t.add("app.contact.admin.menu", "Contacts", "messages", "fr");
t.add("app.contact.admin.fields.civilite.label", "Civilit\u00e9", "messages", "fr");
t.add("app.contact.admin.fields.civilite.placeholder", "Choisir dans la liste", "messages", "fr");
t.add("app.contact.admin.fields.civilite.monsieur", "Monsieur", "messages", "fr");
t.add("app.contact.admin.fields.civilite.madame", "Madame", "messages", "fr");
t.add("app.contact.admin.fields.civilite.docteur", "Docteur", "messages", "fr");
t.add("app.contact.admin.fields.civilite.maitre", "Ma\u00eetre", "messages", "fr");
t.add("app.contact.admin.fields.civilite.null", "Non d\u00e9fini", "messages", "fr");
t.add("app.contact.admin.fields.codeClient.label", "Code client", "messages", "fr");
t.add("app.contact.admin.fields.prenom.label", "Pr\u00e9nom", "messages", "fr");
t.add("app.contact.admin.fields.nom.label", "Nom", "messages", "fr");
t.add("app.contact.admin.fields.type.label", "Type de contact", "messages", "fr");
t.add("app.contact.admin.fields.type.placeholder", "S\u00e9lectionner un type de contact", "messages", "fr");
t.add("app.contact.admin.fields.type.gestionnaire_assurance", "Gestionnaire d'assurance", "messages", "fr");
t.add("app.contact.admin.fields.type.gestionnaire_courtier", "Gestionnaire courtier \/ agent", "messages", "fr");
t.add("app.contact.admin.fields.type.assure", "Assur\u00e9 \/ tiers", "messages", "fr");
t.add("app.contact.admin.fields.type.avocat", "Avocat", "messages", "fr");
t.add("app.contact.admin.fields.type.expert_adverse", "Expert adverse", "messages", "fr");
t.add("app.contact.admin.fields.type.expert_judiciaire", "Expert judiciaire", "messages", "fr");
t.add("app.contact.admin.fields.type.autre", "Autre", "messages", "fr");
t.add("app.contact.admin.fields.adressePro.label", "Type", "messages", "fr");
t.add("app.contact.admin.fields.adressePro.on", "&nbsp;&nbsp;&nbsp;&nbsp;Soci\u00e9t\u00e9&nbsp;&nbsp;&nbsp;&nbsp;", "messages", "fr");
t.add("app.contact.admin.fields.adressePro.off", "Particulier", "messages", "fr");
t.add("app.contact.admin.fields.active.label", "Activ\u00e9 ?", "messages", "fr");
t.add("app.contact.admin.fields.email.label", "Email", "messages", "fr");
t.add("app.contact.admin.fields.fax.label", "Fax", "messages", "fr");
t.add("app.contact.admin.fields.telephone.label", "T\u00e9l\u00e9phone", "messages", "fr");
t.add("app.contact.admin.fields.portable.label", "Portable", "messages", "fr");
t.add("app.contact.admin.fields.rue.label", "Num\u00e9ro et rue", "messages", "fr");
t.add("app.contact.admin.fields.complement.label", "Compl\u00e9ment", "messages", "fr");
t.add("app.contact.admin.fields.codePostal.label", "Code postal", "messages", "fr");
t.add("app.contact.admin.fields.ville.label", "Ville", "messages", "fr");
t.add("app.contact.admin.fields.pays.label", "Pays", "messages", "fr");
t.add("app.contact.admin.fields.societes.label", "Soci\u00e9t\u00e9(s) rattach\u00e9e(s)", "messages", "fr");
t.add("app.contact.admin.fields.societe.label", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact.admin.fields.service.label", "Service", "messages", "fr");
t.add("app.contact.admin.fields.emailPerso.label", "Email perso", "messages", "fr");
t.add("app.contact.admin.fields.emailService.label", "Email service", "messages", "fr");
t.add("app.contact.admin.fields.adresse.label", "Adresse", "messages", "fr");
t.add("app.contact.admin.filters.query.label", "Recherche", "messages", "fr");
t.add("app.contact.admin.filters.query.placeholder", "Raison sociale, email, adresse ...", "messages", "fr");
t.add("app.contact.admin.filters.types.label", "Type(s) de contact", "messages", "fr");
t.add("app.contact.admin.filters.types.placeholder", "S\u00e9lectionner un ou plusieurs types", "messages", "fr");
t.add("app.contact.admin.filters.active.label", "Actifs", "messages", "fr");
t.add("app.contact.admin.filters.active.placeholder", "Indiff\u00e9rent", "messages", "fr");
t.add("app.contact.admin.filters.active.on", "Contacts activ\u00e9s", "messages", "fr");
t.add("app.contact.admin.filters.active.off", "Contacts d\u00e9sactiv\u00e9s", "messages", "fr");
t.add("app.contact.admin.actions.add_societe", "Rattacher une adresse de soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact.admin.actions.edit_societe", "Modifier une soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact.admin.actions.add_contact", "Ajouter un contact", "messages", "fr");
t.add("app.contact.admin.actions.edit_contact", "Modifier un contact", "messages", "fr");
t.add("app.contact_assure.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.contact_assure.admin.fields.contact.label", "Contact assur\u00e9", "messages", "fr");
t.add("app.contact_assure.admin.fields.contact.placeholder", "S\u00e9lectionner une soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact_assure.admin.fields.contactNomPropre.label", "Contact assur\u00e9", "messages", "fr");
t.add("app.contact_assure.admin.fields.contactNomPropre.placeholder", "S\u00e9lectionner un contact", "messages", "fr");
t.add("app.contact_societe.admin.fields.societe.label", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact_societe.admin.fields.societe.placeholder", "S\u00e9lectionner une soci\u00e9t\u00e9", "messages", "fr");
t.add("app.contact_societe.admin.fields.contact.label", "Contact", "messages", "fr");
t.add("app.contact_societe.admin.fields.contact.placeholder", "Nouveau contact", "messages", "fr");
t.add("app.dossier.admin.collection.add.cabinetAvocat", "Ajouter un cabinet d'avocats", "messages", "fr");
t.add("app.dossier.admin.collection.add.cabinetExpertise", "Ajouter un autre cabinet d'expertise", "messages", "fr");
t.add("app.dossier.admin.collection.add.cabinetExpertiseAdverse", "Ajouter un cabinet d'expertise adverse", "messages", "fr");
t.add("app.dossier.admin.collection.add.contactAssure", "Ajouter un contact assur\u00e9", "messages", "fr");
t.add("app.dossier.admin.collection.add.gestionnaireAgent", "Ajouter un gestionnaire courtier \/ Agent", "messages", "fr");
t.add("app.dossier.admin.collection.add.gestionnaireAssurance", "Ajouter un gestionnaire d'assurance", "messages", "fr");
t.add("app.dossier.admin.collection.add.operationExpertise", "Ajouter une op\u00e9rations d'expertises", "messages", "fr");
t.add("app.dossier.admin.collection.add.operationExpertiseEnvoiRapport", "Ajouter une date d'envoi de rapport", "messages", "fr");
t.add("app.dossier.admin.collection.add.tier", "Ajouter un tiers", "messages", "fr");
t.add("app.dossier.admin.export.excelComplet.action.download", "T\u00e9l\u00e9charger le fichier", "messages", "fr");
t.add("app.dossier.admin.export.excelComplet.alert.error", "Une erreur est survenue durant l'export des donn\u00e9es", "messages", "fr");
t.add("app.dossier.admin.export.excelComplet.alert.success.done", "L'export est termin\u00e9", "messages", "fr");
t.add("app.dossier.admin.export.excelComplet.label", "Export complet Excel", "messages", "fr");
t.add("app.dossier.admin.export.excelComplet.totalItemsCount", "%totalItemsCount% dossiers \u00e0 exporter", "messages", "fr");
t.add("app.dossier.admin.historique.dateCreationHistorique", "Le %date% \u00e0 %time%", "messages", "fr");
t.add("app.dossier.admin.index.actions.cloturer", "Cl\u00f4turer", "messages", "fr");
t.add("app.dossier.admin.index.actions.exporter_zip", "Exporter les pi\u00e8ces du dossier", "messages", "fr");
t.add("app.dossier.admin.index.actions.reactiver", "R\u00e9activer", "messages", "fr");
t.add("app.dossier.admin.index.actions.redige", "R\u00e9diger un document", "messages", "fr");
t.add("app.dossier.admin.menu", "Dossiers", "messages", "fr");
t.add("app.dossier.admin.title", "Dossiers", "messages", "fr");
t.add("app.dossier.admin.fields.affaire.label", "Affaire", "messages", "fr");
t.add("app.dossier.admin.fields.affaire.titre", "Affaire : <b>%affaire%<\/b>", "messages", "fr");
t.add("app.dossier.admin.fields.agent.label", "Courtier \/ agent", "messages", "fr");
t.add("app.dossier.admin.fields.approbateur.label", "Approbateur", "messages", "fr");
t.add("app.dossier.admin.fields.approbateur.placeholder", "S\u00e9lectionner un approbateur", "messages", "fr");
t.add("app.dossier.admin.fields.assure.label", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.dossier.admin.fields.assurance.label", "Compagnie d'assurance", "messages", "fr");
t.add("app.dossier.admin.fields.autre_adresse_facturation.label", "Autre adresse de facturation ?", "messages", "fr");
t.add("app.dossier.admin.fields.autre_adresse_facturation.placeholder", "Choisir une soci\u00e9t\u00e9", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier.label", "Choix copie du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier.placeholder", "Choix copie du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier2.label", "Choix copie 2 du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier2.placeholder", "Choix copie du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier3.label", "Choix copie 3 du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.copie_dossier3.placeholder", "Choix copie du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.dateReceptionMission.label", "Date de r\u00e9ception de mission", "messages", "fr");
t.add("app.dossier.admin.fields.dateSinistre.label", "Date du sinistre", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier.label", "Choix destinataire du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier.placeholder", "Choix destinataire du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier2.label", "Choix destinataire 2 du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier2.placeholder", "Choix destinataire du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier3.label", "Choix destinataire 3 du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.destinataire_dossier3.placeholder", "Choix destinataire du dossier", "messages", "fr");
t.add("app.dossier.admin.fields.etat.label", "\u00c9tat", "messages", "fr");
t.add("app.dossier.admin.fields.etat.actif", "Actifs", "messages", "fr");
t.add("app.dossier.admin.fields.etat.archive", "Archiv\u00e9s", "messages", "fr");
t.add("app.dossier.admin.fields.etat.clos", "Clos", "messages", "fr");
t.add("app.dossier.admin.fields.archive.title", "Dossier archiv\u00e9 : %commentaire%", "messages", "fr");
t.add("app.dossier.admin.fields.expert.label", "Expert", "messages", "fr");
t.add("app.dossier.admin.fields.experts.label", "Expert(s)", "messages", "fr");
t.add("app.dossier.admin.fields.experts.placeholder", "S\u00e9lectionner un ou plusieurs experts", "messages", "fr");
t.add("app.dossier.admin.fields.id.label", "ID", "messages", "fr");
t.add("app.dossier.admin.fields.initialeExperts.label", "Initiales expert(s)", "messages", "fr");
t.add("app.dossier.admin.fields.initialeExperts.placeholder", "S\u00e9lectionner des initiales", "messages", "fr");
t.add("app.dossier.admin.fields.is_nom_propre.label", "Exclure de l'annuaire", "messages", "fr");
t.add("app.dossier.admin.fields.lieuSinistre.label", "Lieu du sinistre", "messages", "fr");
t.add("app.dossier.admin.fields.mandant.label", "Mandant ?", "messages", "fr");
t.add("app.dossier.admin.fields.numero_dossier.label", "N\u00b0", "messages", "fr");
t.add("app.dossier.admin.fields.numero_dossier.title", "Num\u00e9ro de dossier", "messages", "fr");
t.add("app.dossier.admin.fields.reference_police.label", "R\u00e9f\u00e9rences police\/contrat", "messages", "fr");
t.add("app.dossier.admin.fields.reference_sinistre.label", "R\u00e9f\u00e9rences sinistre", "messages", "fr");
t.add("app.dossier.admin.fields.trame.label", "S\u00e9lection de la trame", "messages", "fr");
t.add("app.dossier.admin.fields.trame.placeholder", "S\u00e9lection de la trame", "messages", "fr");
t.add("app.dossier.admin.fields.procedure.label", "Proc\u00e9dure", "messages", "fr");
t.add("app.dossier.admin.fields.procedure.placeholder", "S\u00e9lectionner une proc\u00e9dure", "messages", "fr");
t.add("app.dossier.admin.fields.garantie.label", "Garantie", "messages", "fr");
t.add("app.dossier.admin.fields.garantie.placeholder", "S\u00e9lectionner une garantie", "messages", "fr");
t.add("app.dossier.admin.fields.nature.label", "Nature", "messages", "fr");
t.add("app.dossier.admin.fields.nature.placeholder", "S\u00e9lectionner une nature", "messages", "fr");
t.add("app.dossier.admin.fields.nouveauCommentaireTitre.label", "Titre", "messages", "fr");
t.add("app.dossier.admin.fields.nouveauCommentaire.label", "Contenu", "messages", "fr");
t.add("app.dossier.admin.fields.resume.label", "R\u00e9sum\u00e9 des d\u00e9sordres", "messages", "fr");
t.add("app.dossier.admin.fields.budget.label", "Budget", "messages", "fr");
t.add("app.dossier.admin.fields.enjeuFinancier.label", "Enjeu financier", "messages", "fr");
t.add("app.dossier.admin.fields.enjeuFinancierDe.label", "De", "messages", "fr");
t.add("app.dossier.admin.fields.enjeuFinancierA.label", "\u00e0", "messages", "fr");
t.add("app.dossier.admin.fields.expertJudiciaire.label", "Expert judiciaire", "messages", "fr");
t.add("app.dossier.admin.fields.expertJudiciaire.placeholder", "S\u00e9lectionner un expert judiciaire", "messages", "fr");
t.add("app.dossier.admin.fields.judiciaire.label", "Judiciaire", "messages", "fr");
t.add("app.dossier.admin.fields.typeExpertise.label", "Type d'expertise", "messages", "fr");
t.add("app.dossier.admin.fields.typeExpertise.placeholder", "S\u00e9lectionner un type d'expertise", "messages", "fr");
t.add("app.dossier.admin.fields.dossierDarva.label", "Dossier DARVA ?", "messages", "fr");
t.add("app.dossier.admin.fields.dossierMercure.label", "Dossier MERCURE ?", "messages", "fr");
t.add("app.dossier.admin.fields.dateOuverture.label", "Date d'ouverture", "messages", "fr");
t.add("app.dossier.admin.fields.darvaMercure.label", "DARVA \/ MERCURE", "messages", "fr");
t.add("app.dossier.admin.fieldset.agent", "Cabinet courtier \/ Agent", "messages", "fr");
t.add("app.dossier.admin.fieldset.assure", "Assur\u00e9", "messages", "fr");
t.add("app.dossier.admin.fieldset.contact_assure", "Contact assur\u00e9", "messages", "fr");
t.add("app.dossier.admin.fieldset.cabinet_avocat", "Cabinet d'avocats", "messages", "fr");
t.add("app.dossier.admin.fieldset.cabinet_expertise", "Autre cabinet d'expertise", "messages", "fr");
t.add("app.dossier.admin.fieldset.cabinet_expertise_adverse", "Cabinet d'expertise adverse", "messages", "fr");
t.add("app.dossier.admin.fieldset.compagnie_assurance", "Compagnie d'assurance", "messages", "fr");
t.add("app.dossier.admin.fieldset.gestionnaire_agent", "Gestionnaire courtier \/ Agent", "messages", "fr");
t.add("app.dossier.admin.fieldset.gestionnaire_assurance", "Gestionnaire d'assurance", "messages", "fr");
t.add("app.dossier.admin.fieldset.historiques", "Historique", "messages", "fr");
t.add("app.dossier.admin.fieldset.informations_dossier", "Informations du dossier", "messages", "fr");
t.add("app.dossier.admin.fieldset.note_de_frais_globale", "Feuille de facturation", "messages", "fr");
t.add("app.dossier.admin.fieldset.operations_expertises", "Op\u00e9rations d'expertises", "messages", "fr");
t.add("app.dossier.admin.fieldset.pieces_dossiers", "Pi\u00e8ces du dossier", "messages", "fr");
t.add("app.dossier.admin.fieldset.redaction", "R\u00e9daction courrier\/rapport pour le dossier :", "messages", "fr");
t.add("app.dossier.admin.fieldset.tier", "Tiers", "messages", "fr");
t.add("app.dossier.admin.filters.approbateur.label", "Approbateur", "messages", "fr");
t.add("app.dossier.admin.filters.approbateur.placeholder", "Approbateur", "messages", "fr");
t.add("app.dossier.admin.filters.createdAt.label.au", "Au", "messages", "fr");
t.add("app.dossier.admin.filters.createdAt.label.du", "Date de cr\u00e9ation: Du", "messages", "fr");
t.add("app.dossier.admin.filters.createdAt.placeholder.au", "Au", "messages", "fr");
t.add("app.dossier.admin.filters.createdAt.placeholder.du", "Du", "messages", "fr");
t.add("app.dossier.admin.filters.dossierDarva.label", "DARVA ?", "messages", "fr");
t.add("app.dossier.admin.filters.dossierMercure.label", "MERCURE ?", "messages", "fr");
t.add("app.dossier.admin.filters.etat.all", "Tous", "messages", "fr");
t.add("app.dossier.admin.filters.etat.label", "Etat", "messages", "fr");
t.add("app.dossier.admin.filters.etat.placeholder", "Etat", "messages", "fr");
t.add("app.dossier.admin.filters.expert.label", "Expert", "messages", "fr");
t.add("app.dossier.admin.filters.expert.placeholder", "Expert", "messages", "fr");
t.add("app.dossier.admin.filters.history_type.label", "Action de type", "messages", "fr");
t.add("app.dossier.admin.filters.history_type.placeholder", "Tous les types", "messages", "fr");
t.add("app.dossier.admin.filters.judiciaire.label", "Judiciaire ?", "messages", "fr");
t.add("app.dossier.admin.filters.query.label", "Recherche", "messages", "fr");
t.add("app.dossier.admin.filters.query.placeholder", "Mot cl\u00e9 ou N\u00b0 de dossier", "messages", "fr");
t.add("app.dossier.admin.filters.typeProcedure.label", "Proc\u00e9dure", "messages", "fr");
t.add("app.dossier.admin.tabs.assureur", "Assureur", "messages", "fr");
t.add("app.dossier.admin.tabs.agent", "Courtier \/ Agent", "messages", "fr");
t.add("app.dossier.admin.tabs.assure", "Assur\u00e9", "messages", "fr");
t.add("app.dossier.admin.tabs.tiers", "Tiers", "messages", "fr");
t.add("app.dossier.admin.tabs.information_dossier", "Informations du dossier", "messages", "fr");
t.add("app.dossier.admin.tabs.operation_expertise", "Op\u00e9rations d'expertises", "messages", "fr");
t.add("app.dossier.admin.tabs.pieces_dossier", "Pi\u00e8ces du dossier", "messages", "fr");
t.add("app.dossier.admin.tabs.redige", "R\u00e9diger", "messages", "fr");
t.add("app.dossier.admin.tabs.historique", "Historique", "messages", "fr");
t.add("app.dossier.admin.tabs.note_frais", "Feuille de facturation", "messages", "fr");
t.add("app.dossier.admin.tabs.synthese_facturation", "Synth\u00e8se de facturation", "messages", "fr");
t.add("app.dossier.admin.validation.trame.not_blank", "Une trame est n\u00e9cessaire pour exporter ce dossier.", "messages", "fr");
t.add("app.dossier.admin.actions.postIt", "Post-it", "messages", "fr");
t.add("app.dossier.admin.actions.savePostIt", "Sauvegarder", "messages", "fr");
t.add("app.dossier.admin.actions.closePostIt", "Fermer", "messages", "fr");
t.add("app.dossier.admin.actions.emptyPostIt", "Vider", "messages", "fr");
t.add("app.dossier.admin.actions.cloturer", "%user% a cl\u00f4tur\u00e9 le dossier", "messages", "fr");
t.add("app.dossier.admin.actions.reactiver", "%user% a r\u00e9activ\u00e9 le dossier", "messages", "fr");
t.add("app.dossier.admin.actions.ajout_commentaire", "%user% a ajout\u00e9 un nouveau commentaire", "messages", "fr");
t.add("app.dossier.admin.actions.archiver", "%user% a archiv\u00e9 le dossier", "messages", "fr");
t.add("app.dossier.admin.actions.edit_adresse", "Modifier l'adresse", "messages", "fr");
t.add("app.dossier.admin.actions.add_adresse", "Ajouter une adresse", "messages", "fr");
t.add("app.dossier.admin.actions.worklog", "Ajouter du temps (w)", "messages", "fr");
t.add("app.dossier.admin.actions.date_sinistre_add", "a ajout\u00e9 la date de sinistre au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_sinistre_modifie", "a modifi\u00e9 la date de sinistre au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_reception_mission_add", "a ajout\u00e9 la date de r\u00e9ception de mission au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_reception_mission_modifie", "a modifi\u00e9 la date de r\u00e9ception de mission au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_ouverture_add", "a ajout\u00e9 la date d'ouverture au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_expertise_ajoute", "a enregistr\u00e9 une date d'expertise %nb% au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_expertise_supprime", "a supprim\u00e9 la date d'expertise %nb% du %date%", "messages", "fr");
t.add("app.dossier.admin.actions.date_expertise_modifie", "a mis \u00e0 jour la date d'expertise %nb% au %date%", "messages", "fr");
t.add("app.dossier.admin.actions.operation_expertise_supprime", "a supprim\u00e9 l'op\u00e9ration d'expertise %nb%", "messages", "fr");
t.add("app.dossier.admin.actions.add_note_de_frais", "Ajouter un type de frais", "messages", "fr");
t.add("app.dossier.admin.actions.add_feuille", "Ajouter une feuille", "messages", "fr");
t.add("app.dossier.admin.actions.cloture_note_de_frais", "Cl\u00f4re la feuille", "messages", "fr");
t.add("app.dossier.admin.actions.type_expertise_modifie", "a modifi\u00e9 le type d'exprtise de \"%old%\" \u00e0 \"%new%\"", "messages", "fr");
t.add("app.dossier.admin.actions.telecharger_note_de_frais", "T\u00e9l\u00e9charger", "messages", "fr");
t.add("app.dossier.admin.actions.classement_email", "a class\u00e9 l'email \"%sujet%\" dans l'onglet \"%onglet%\"", "messages", "fr");
t.add("app.dossier.admin.actions.classement_email_avec_indicateur", "a class\u00e9 l'email \"%sujet%\" dans l'onglet \"%onglet%\" (indicateur : \"%indicateur%\")", "messages", "fr");
t.add("app.dossier.admin.actions.filters.cloturer", "Cloturation", "messages", "fr");
t.add("app.dossier.admin.actions.filters.reactiver", "R\u00e9activation", "messages", "fr");
t.add("app.dossier.admin.actions.filters.ajout_commentaire", "Ajout de commentaire", "messages", "fr");
t.add("app.dossier.admin.actions.filters.archiver", "Archivage", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_sinistre_add", "Ajout de date de sinistre", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_sinistre_modifie", "Modification de date de sinistre", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_reception_mission_add", "Ajout de date de r\u00e9ception de mission", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_reception_mission_modifie", "Modification de date de r\u00e9ception de mission", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_ouverture_add", "Ajout de date d'ouverture", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_expertise_ajoute", "Ajout de date d'expertise", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_expertise_supprime", "Suppression de date d'expertise", "messages", "fr");
t.add("app.dossier.admin.actions.filters.date_ouverture_modifie", "Modification de date d'expertise", "messages", "fr");
t.add("app.dossier.admin.actions.filters.operation_expertise_supprime", "Suppression d'op\u00e9ration d'expertise", "messages", "fr");
t.add("app.dossier.admin.actions.filters.type_expertise_modifie", "Modification de type d'expertise", "messages", "fr");
t.add("app.dossier.admin.actions.filters.classement_email", "Classement d'email", "messages", "fr");
t.add("app.dossier.admin.actions.filters.classement_email_avec_indicateur", "Classement d'email avec indicateur", "messages", "fr");
t.add("app.dossier.admin.modal.confirmReinitLieuExpertise.content", "\u00cates-vous s\u00fbr de vouloir effacer le lieu d'expertise ?", "messages", "fr");
t.add("app.dossier.admin.modal.confirmReinitLieuExpertise.title", "Confirmation d'effacement", "messages", "fr");
t.add("app.dossier.admin.modal.archiver.actions.sauvegarder", "Archiver", "messages", "fr");
t.add("app.dossier.admin.modal.archiver.fields.commentaireArchivage.label", "O\u00f9 est stock\u00e9 l'archive ?", "messages", "fr");
t.add("app.dossier.admin.modal.archiver.title", "Archiver les dossiers", "messages", "fr");
t.add("app.dossier.admin.modal.worklog.title", "Ajouter du temps", "messages", "fr");
t.add("app.dossier.admin.modal.modalConfirmWithNoteNonCloture.title", "Une feuille note de frais n'est pas cl\u00f4tur\u00e9e", "messages", "fr");
t.add("app.dossier.admin.modal.modalConfirmWithNoteNonCloture.body", "Attention vous essayez de cl\u00f4re un dossier mais une feuille de note de frais n'a pas \u00e9t\u00e9 cl\u00f4tur\u00e9e.", "messages", "fr");
t.add("app.dossier.admin.modal.modalConfirmCloture.title", "Confirmation de cl\u00f4ture", "messages", "fr");
t.add("app.dossier.admin.modal.modalConfirmCloture.body", "Confirmez-vous la cl\u00f4ture du dossier ?", "messages", "fr");
t.add("app.dossier.admin.table.actions", "Actions", "messages", "fr");
t.add("app.dossier.admin.table.type_note_frais", "Type de note de frais", "messages", "fr");
t.add("app.dossier.admin.table.type", "Note de frais", "messages", "fr");
t.add("app.dossier.admin.table.date", "Date", "messages", "fr");
t.add("app.dossier.admin.table.duree", "Quantit\u00e9", "messages", "fr");
t.add("app.dossier.admin.table.intervenant", "Intervenant", "messages", "fr");
t.add("app.dossier.admin.table.commentaire", "Commentaire", "messages", "fr");
t.add("app.dossier.admin.note_de_frais.type", "%type% : %nb% %heures%", "messages", "fr");
t.add("app.dossier.admin.note_de_frais.number", "messages", "messages", "fr");
t.add("app.dossier.admin.note_de_frais.tabs.number", "Feuille n\u00b0%nb%", "messages", "fr");
t.add("app.dossier.admin.pdf.note_frais.title", "Dossier %dossier%", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.create_directory", "Cr\u00e9er un sous-dossier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.deja_exporte", "Pi\u00e8ces du dossier export\u00e9es le %date%", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.fields.name.label", "Nom du fichier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.fields.sousDossier.label", "Dossier de destination", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.fields.submit.label", "Renommer", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.file_already_exist", "Un fichier de ce nom est d\u00e9j\u00e0 \u00e9xistant voulez-vous le remplacer ?", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.loading", "Chargement en cours, veuillez patienter...", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.create.submit", "Cr\u00e9er", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.create.title", "Cr\u00e9er un sous-dossier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.delete.body", "Confirmez la suppression de", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.delete.title", "Supprimer un fichier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.deleteFolder.body", "Confirmez la suppression du dossier, de ses sous-dossiers et fichiers", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.deleteFolder.title", "Supprimer un dossier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.download.title", "T\u00e9l\u00e9charger un fichier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.exportPiecesDossier.content", "\u00cates-vous s\u00fbr de vouloir exporter les pi\u00e8ces du dossier ?<br> Les fichiers seront supprim\u00e9s du serveur une fois l'export termin\u00e9", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.exportPiecesDossier.title", "Exporter les pi\u00e8ces du dossier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.move.submit", "D\u00e9placer", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.move.title", "D\u00e9placer un fichier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.rename.submit", "Renommer", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.rename.title", "Renommer un fichier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.unzip.submit", "D\u00e9zipper", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.modal.unzip.title", "D\u00e9zipper une archive", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.no_directories", "Aucun dossier trouv\u00e9", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.no_files", "Aucun fichier trouv\u00e9", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.refresh_directory", "Actualiser le dossier", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.table.name", "R\u00e9pertoire", "messages", "fr");
t.add("app.dossier.admin.pieces_dossiers.table.actions", "Actions", "messages", "fr");
t.add("app.dossier.admin.titre.repartition", "Pourcentages de commissions", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.date.label", "Date", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.type.label", "Type de frais", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.type.placeholder", "S\u00e9lectionner un type de frais", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.valeur.label", "Temps", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.unite.label", "Unit\u00e9", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.unite.h", "heure", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.unite.m", "minute", "messages", "fr");
t.add("app.dossier_worklog.admin.fields.commentaire.label", "Commentaire", "messages", "fr");
t.add("app.dossier_worklog.admin.success.add", "Temps ajout\u00e9 avec succ\u00e8s", "messages", "fr");
t.add("app.famille_document.admin.menu", "Familles de documents", "messages", "fr");
t.add("app.famille_document.admin.title", "Familles de documents", "messages", "fr");
t.add("app.famille_document.admin.fields.name.label", "Nom de la famille", "messages", "fr");
t.add("app.famille_document.admin.fields.tab.label", "Pr\u00e9sent dans l'onglet", "messages", "fr");
t.add("app.famille_document.admin.fields.tab.placeholder", "Par d\u00e9faut", "messages", "fr");
t.add("app.famille_document.admin.filters.name.placeholder", "Nom de la famille", "messages", "fr");
t.add("app.gestionnaire_agent.admin.fields.gestionnaire.label", "Gestionnaire courtier \/ Agent", "messages", "fr");
t.add("app.gestionnaire_agent.admin.fields.gestionnaire.placeholder", "Choisir un courtier \/ Agent", "messages", "fr");
t.add("app.gestionnaire_agent.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.gestionnaire_assurance.admin.fields.gestionnaire.label", "Gestionnaire d'assurance", "messages", "fr");
t.add("app.gestionnaire_assurance.admin.fields.gestionnaire.placeholder", "Choisir un gestionnaire", "messages", "fr");
t.add("app.gestionnaire_assurance.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.indicateur.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.indicateur.admin.menu", "Indicateurs", "messages", "fr");
t.add("app.indicateur.admin.title", "Indicateurs", "messages", "fr");
t.add("app.initial_expert.admin.fields.expert.label", "Expert", "messages", "fr");
t.add("app.initial_expert.admin.fields.initial.label", "Initial", "messages", "fr");
t.add("app.initial_expert.admin.menu", "Initiales d'expert", "messages", "fr");
t.add("app.initial_expert.admin.title", "Initiales d'expert", "messages", "fr");
t.add("app.nature_dossier.admin.title", "Natures dossier", "messages", "fr");
t.add("app.nature_dossier.admin.menu", "Natures dossier", "messages", "fr");
t.add("app.nature_dossier.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.note_de_frais.admin.actions.sauvegarder", "Enregistrer", "messages", "fr");
t.add("app.note_de_frais.admin.fields.commentaire.label", "Commentaire", "messages", "fr");
t.add("app.note_de_frais.admin.fields.intervenant.label", "Intervenant", "messages", "fr");
t.add("app.note_de_frais.admin.fields.intervenant.placeholder", "Choisir un intervenant", "messages", "fr");
t.add("app.note_de_frais.admin.fields.date.label", "Date", "messages", "fr");
t.add("app.note_de_frais.admin.fields.duree.label", "Quantit\u00e9", "messages", "fr");
t.add("app.note_de_frais.admin.fields.typeNoteFrais.label", "Type de frais", "messages", "fr");
t.add("app.note_de_frais.admin.fields.typeNoteFrais.placeholder", "Choisir un type", "messages", "fr");
t.add("app.note_de_frais.admin.fields.unite.label", "Unit\u00e9", "messages", "fr");
t.add("app.note_de_frais.admin.modal.title", "Ajout d'une note de frais", "messages", "fr");
t.add("app.note_de_frais.admin.modal_cloture_ndf.title", "Cl\u00f4ture d'une note", "messages", "fr");
t.add("app.note_de_frais.admin.modal_delete_ndf.title", "Suppression d'une note", "messages", "fr");
t.add("app.note_de_frais.admin.modal_delete_ndf_globale.title", "Suppression d'une feuille", "messages", "fr");
t.add("app.note_de_frais.admin.modal_update_ndf.title", "Modification d'une note", "messages", "fr");
t.add("app.operation_expertise.admin.fields.complementLieuExpertise.label", "Compl\u00e9ment d'adresse", "messages", "fr");
t.add("app.operation_expertise.admin.fields.courrierMiseEnCause.label", "Trames de document", "messages", "fr");
t.add("app.operation_expertise.admin.fields.cpLieuExpertise.label", "Code postal", "messages", "fr");
t.add("app.operation_expertise.admin.fields.dateExpertise.label", "Date d'expertise", "messages", "fr");
t.add("app.operation_expertise.admin.fields.dateMiseEnCause.label", "Date de mise en cause", "messages", "fr");
t.add("app.operation_expertise.admin.fields.envoiRapport.label", "Date d'envoi du rapport", "messages", "fr");
t.add("app.operation_expertise.admin.fields.excluStat.label", "Op\u00e9ration sans d\u00e9placement ?", "messages", "fr");
t.add("app.operation_expertise.admin.fields.expert.label", "Expert CDH", "messages", "fr");
t.add("app.operation_expertise.admin.fields.expert.placeholder", "S\u00e9lectionner un expert", "messages", "fr");
t.add("app.operation_expertise.admin.fields.id.label", "ID", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.adresseSiegeAssure", "Adresse si\u00e8ge assur\u00e9", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.adresseSiteAssure", "Adresse site assur\u00e9", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.adresseTiers1", "Adresse tiers 1", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.adresseTiers2", "Adresse tiers 2", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.autre", "Autre", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.label", "Lieu de l'expertise", "messages", "fr");
t.add("app.operation_expertise.admin.fields.lieuExpertise.placeholder", "S\u00e9lectionner un lieu d'expertise", "messages", "fr");
t.add("app.operation_expertise.admin.fields.paysLieuExpertise.label", "Pays", "messages", "fr");
t.add("app.operation_expertise.admin.fields.rapportReconnaissance.help_block", "Ne pas remplir la date si ne voulez pas faire de rapport de reconnaissance", "messages", "fr");
t.add("app.operation_expertise.admin.fields.rapportReconnaissance.label", "Date du rapport de reconnaissance", "messages", "fr");
t.add("app.operation_expertise.admin.fields.rapportReconnaissance.warning", "Attention, pour les dossiers darva ou mercure ne pas oublier de remplir la date sur le logiciel client.", "messages", "fr");
t.add("app.operation_expertise.admin.fields.rueLieuExpertise.label", "Num\u00e9ro et rue", "messages", "fr");
t.add("app.operation_expertise.admin.fields.villeLieuExpertise.label", "Ville", "messages", "fr");
t.add("app.operation_expertise.admin.fieldset.operationExpertiseEnvoiRapports", "Envoi rapport", "messages", "fr");
t.add("app.operation_expertise.admin.modal.update_date_rapport.title", "Ajouter une date de rapport de reconnaissance", "messages", "fr");
t.add("app.operation_expertise.admin.modal.add_date_envoi_rapport.title", "Ajouter une date d'envoi de rapport", "messages", "fr");
t.add("app.operation_expertise.admin.numero", "Expertise N\u00b0<span class=\"numerotation\"><\/span>", "messages", "fr");
t.add("app.operation_expertise.admin.numero_short", "Exp N\u00b0", "messages", "fr");
t.add("app.societe.admin.title", "Soci\u00e9t\u00e9s", "messages", "fr");
t.add("app.societe.admin.menu", "Soci\u00e9t\u00e9s", "messages", "fr");
t.add("app.societe.admin.actions.add_adresse", "Ajouter une adresse", "messages", "fr");
t.add("app.societe.admin.actions.save_export", "Ouvrir", "messages", "fr");
t.add("app.societe.admin.fields.name.label", "Raison sociale", "messages", "fr");
t.add("app.societe.admin.fields.type.label", "Type de soci\u00e9t\u00e9", "messages", "fr");
t.add("app.societe.admin.fields.type.placeholder", "S\u00e9lectionner un type de soci\u00e9t\u00e9", "messages", "fr");
t.add("app.societe.admin.fields.type.compagnie_assurance", "Compagnie d'assurance", "messages", "fr");
t.add("app.societe.admin.fields.type.courtier", "Cabinet courtier \/ agent", "messages", "fr");
t.add("app.societe.admin.fields.type.assure", "Soci\u00e9t\u00e9 assur\u00e9 \/ tiers", "messages", "fr");
t.add("app.societe.admin.fields.type.avocat", "Cabinet d'avocats", "messages", "fr");
t.add("app.societe.admin.fields.type.expertise_adverse", "Cabinet d'expertises adverse", "messages", "fr");
t.add("app.societe.admin.fields.type.expertise_judiciaire", "Expert judiciaire", "messages", "fr");
t.add("app.societe.admin.fields.type.autre", "Autre", "messages", "fr");
t.add("app.societe.admin.fields.active.label", "Activ\u00e9 ?", "messages", "fr");
t.add("app.societe.admin.fields.email.label", "Email", "messages", "fr");
t.add("app.societe.admin.fields.fax.label", "Fax", "messages", "fr");
t.add("app.societe.admin.fields.telephone.label", "T\u00e9l\u00e9phone", "messages", "fr");
t.add("app.societe.admin.fields.adresseSelectionne.label", "Adresse s\u00e9lectionn\u00e9e", "messages", "fr");
t.add("app.societe.admin.fields.adresseSelectionne.placeholder", "Nouvelle adresse", "messages", "fr");
t.add("app.societe.admin.fields.service.label", "Service", "messages", "fr");
t.add("app.societe.admin.fields.adresse.label", "Adresse", "messages", "fr");
t.add("app.societe.admin.fields.ville.label", "Ville", "messages", "fr");
t.add("app.societe.admin.fields.cp.label", "Code postal", "messages", "fr");
t.add("app.societe.admin.fields.pays.label", "Pays", "messages", "fr");
t.add("app.societe.admin.fields.codeClient.label", "Code client", "messages", "fr");
t.add("app.societe.admin.filters.query.label", "Recherche", "messages", "fr");
t.add("app.societe.admin.filters.query.placeholder", "Raison sociale, email, adresse ...", "messages", "fr");
t.add("app.societe.admin.filters.service.label", "Service", "messages", "fr");
t.add("app.societe.admin.filters.service.placeholder", "Nom du service...", "messages", "fr");
t.add("app.societe.admin.filters.types.label", "Type(s) de soci\u00e9t\u00e9", "messages", "fr");
t.add("app.societe.admin.filters.types.placeholder", "S\u00e9lectionner un ou plusieurs types", "messages", "fr");
t.add("app.societe.admin.filters.active.label", "Actives", "messages", "fr");
t.add("app.societe.admin.filters.active.placeholder", "Indiff\u00e9rent", "messages", "fr");
t.add("app.societe.admin.filters.active.on", "Soci\u00e9t\u00e9s activ\u00e9es", "messages", "fr");
t.add("app.societe.admin.filters.active.off", "Soci\u00e9t\u00e9s d\u00e9sactiv\u00e9es", "messages", "fr");
t.add("app.societe_adresse.admin.fields.active.label", "Activ\u00e9e ?", "messages", "fr");
t.add("app.societe_adresse.admin.fields.codeClient.label", "Code client", "messages", "fr");
t.add("app.societe_adresse.admin.fields.societe.label", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.societe_adresse.admin.fields.societe.placeholder", "Nouvelle soci\u00e9t\u00e9", "messages", "fr");
t.add("app.societe_adresse.admin.fields.email.label", "E-mail", "messages", "fr");
t.add("app.societe_adresse.admin.fields.name.label", "Service", "messages", "fr");
t.add("app.societe_adresse.admin.fields.rue.label", "Num\u00e9ro et rue", "messages", "fr");
t.add("app.societe_adresse.admin.fields.complement.label", "Compl\u00e9ment", "messages", "fr");
t.add("app.societe_adresse.admin.fields.codePostal.label", "Code postal", "messages", "fr");
t.add("app.societe_adresse.admin.fields.ville.label", "Ville", "messages", "fr");
t.add("app.societe_adresse.admin.fields.pays.label", "Pays", "messages", "fr");
t.add("app.sous_dossier.admin.actions.desactive", "D\u00e9sactiver le sous dossier", "messages", "fr");
t.add("app.sous_dossier.admin.fields.enabled.label", "Activ\u00e9 ?", "messages", "fr");
t.add("app.sous_dossier.admin.fields.indicateur.label", "Li\u00e9 \u00e0 un indicateur ?", "messages", "fr");
t.add("app.sous_dossier.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.sous_dossier.admin.menu", "Sous-dossier", "messages", "fr");
t.add("app.sous_dossier.admin.title", "Sous-dossier", "messages", "fr");
t.add("app.tache.admin.fields.action.label", "Action", "messages", "fr");
t.add("app.tache.admin.fields.action.placeholder", "S\u00e9lectionner une action", "messages", "fr");
t.add("app.tache.admin.fields.attribuerA.label", "Attribu\u00e9 \u00e0", "messages", "fr");
t.add("app.tache.admin.fields.complementInformation.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.tache.admin.fields.dateEcheance.label", "Date d'\u00e9ch\u00e9ance", "messages", "fr");
t.add("app.tache.admin.fields.dossier.label", "Dossier", "messages", "fr");
t.add("app.tache.admin.fields.dossier.placeholder", "S\u00e9lectionner un dossier", "messages", "fr");
t.add("app.tache.admin.fields.tachePersonnelle.label", "T\u00e2che personnelle ?", "messages", "fr");
t.add("app.tache.admin.fields.createdBy.label", "Cr\u00e9\u00e9 par", "messages", "fr");
t.add("app.tache.admin.fields.updatedAt.label", "Mis \u00e0 jour le", "messages", "fr");
t.add("app.tache.admin.fields.createdAt.label", "Cr\u00e9\u00e9 le", "messages", "fr");
t.add("app.tache.admin.kanban.etapes.a_faire", "\u00c0 faire", "messages", "fr");
t.add("app.tache.admin.kanban.etapes.en_cours", "En cours", "messages", "fr");
t.add("app.tache.admin.kanban.etapes.termine", "Termin\u00e9", "messages", "fr");
t.add("app.tache.admin.non_attribue", "Non atribu\u00e9", "messages", "fr");
t.add("app.tache.admin.success.moved", "D\u00e9placement enregistr\u00e9", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.auDate.label", "Au", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.auDate.placeholder", "Choisir une date", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.delai.append", "jours", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.delai.label", "D\u00e9lai restant avant alerte", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.duDate.label", "Du", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.duDate.placeholder", "Choisir une date", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.expert.label", "Expert", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.expert.placeholder", "Choisir un expert", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.numeroDossier.label", "Num\u00e9ro de dossier", "messages", "fr");
t.add("app.tableau_synoptique.admin.filters.showAll.label", "Voir l\u2019int\u00e9gralit\u00e9 du kanban ?", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.action.boite_commune", "Finalisation facturation - Dossier %numeroDossier% - Exp N\u00b0%numeroExpertise%", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.action.controle_qualite_relecture", "Relecture - Dossier %numeroDossier% - Exp N\u00b0%numeroExpertise%", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.action.rapport", "Faire le rapport - Dossier %numeroDossier% - Exp N\u00b0%numeroExpertise%", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.action.rapport_reconnaissance", "Faire le rapport de reconnaissance - Dossier %numeroDossier% - Exp N\u00b0%numeroExpertise%", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.attente_rapport", "En attente rapport", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.attente_rapport_reconnaissance", "En attente rapport de reconnaissance", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.boite_commune", "Bo\u00eete commune", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.controle_qualite_relecture", "Contr\u00f4le qualit\u00e9 \/ En attente relecture", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.finalisation_facturation", "Finalisation Facturation", "messages", "fr");
t.add("app.tableau_synoptique.admin.kanban.etapes.termine", "Termin\u00e9", "messages", "fr");
t.add("app.tableau_synoptique.admin.title", "Tableau synoptique", "messages", "fr");
t.add("app.tache_commune.admin.fields.action.label", "Action", "messages", "fr");
t.add("app.tache_commune.admin.fields.attribuerA.label", "Attribu\u00e9 \u00e0", "messages", "fr");
t.add("app.tache_commune.admin.fields.dateEcheance.label", "Date d'\u00e9ch\u00e9ance", "messages", "fr");
t.add("app.tache_commune.admin.fields.dossier.label", "Dossier", "messages", "fr");
t.add("app.tache_commune.admin.fields.tachePersonnelle.label", "T\u00e2che personnelle ?", "messages", "fr");
t.add("app.tache_commune.admin.title", "T\u00e2ches communes secr\u00e9tariat", "messages", "fr");
t.add("app.tache_personnelle.admin.fields.action.label", "Action", "messages", "fr");
t.add("app.tache_personnelle.admin.fields.attribuerA.label", "Attribu\u00e9 \u00e0", "messages", "fr");
t.add("app.tache_personnelle.admin.fields.dateEcheance.label", "Date d'\u00e9ch\u00e9ance", "messages", "fr");
t.add("app.tache_personnelle.admin.fields.dossier.label", "Dossier", "messages", "fr");
t.add("app.tache_personnelle.admin.fields.tachePersonnelle.label", "T\u00e2che personnelle ?", "messages", "fr");
t.add("app.tache_personnelle.admin.title", "T\u00e2ches personnelles", "messages", "fr");
t.add("app.tier.admin.fields.codePostal.label", "Code postal", "messages", "fr");
t.add("app.tier.admin.fields.complement.label", "Compl\u00e9ment d'information", "messages", "fr");
t.add("app.tier.admin.fields.complement_adresse.label", "Compl\u00e9ment d'adresse", "messages", "fr");
t.add("app.tier.admin.fields.contact.label", "Contact(s) tiers", "messages", "fr");
t.add("app.tier.admin.fields.contact.placeholder", "Choisier un contact", "messages", "fr");
t.add("app.tier.admin.fields.email.label", "Email", "messages", "fr");
t.add("app.tier.admin.fields.fax.label", "Fax", "messages", "fr");
t.add("app.tier.admin.fields.is_nom_propre.label", "exclure de l'annuaire", "messages", "fr");
t.add("app.tier.admin.fields.pays.label", "Pays", "messages", "fr");
t.add("app.tier.admin.fields.portable.label", "Portable", "messages", "fr");
t.add("app.tier.admin.fields.raison_social.label", "Raison sociale", "messages", "fr");
t.add("app.tier.admin.fields.reference_police.label", "R\u00e9f\u00e9rences police\/contra", "messages", "fr");
t.add("app.tier.admin.fields.reference_sinistre.label", "R\u00e9f\u00e9rences sinistre", "messages", "fr");
t.add("app.tier.admin.fields.rue.label", "Num\u00e9ro et rue", "messages", "fr");
t.add("app.tier.admin.fields.societe.label", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.tier.admin.fields.societe.placeholder", "Soci\u00e9t\u00e9", "messages", "fr");
t.add("app.tier.admin.fields.telephone.label", "T\u00e9l\u00e9phone", "messages", "fr");
t.add("app.tier.admin.fields.ville.label", "Ville", "messages", "fr");
t.add("app.trame_document.admin.actions.show_dossier_variable", "Variables des dossiers", "messages", "fr");
t.add("app.trame_document.admin.menu", "Trames de documents", "messages", "fr");
t.add("app.trame_document.admin.modals.variables.title", "Liste des variables utilisables", "messages", "fr");
t.add("app.trame_document.admin.title", "Trames de documents", "messages", "fr");
t.add("app.trame_document.admin.fields.document.label", "Document", "messages", "fr");
t.add("app.trame_document.admin.fields.famille.label", "Famille", "messages", "fr");
t.add("app.trame_document.admin.fields.name.label", "Nom de la trame", "messages", "fr");
t.add("app.trame_document.admin.fields.procedure.label", "Proc\u00e9dure", "messages", "fr");
t.add("app.trame_document.admin.filters.name.placeholder", "Nom de la trame", "messages", "fr");
t.add("app.trame_document.admin.validation.unique", "Un document est d\u00e9j\u00e0 enregistr\u00e9 pour cette famille et cette proc\u00e9dure.", "messages", "fr");
t.add("app.type_expertise.admin.menu", "Types d'expertise", "messages", "fr");
t.add("app.type_expertise.admin.title", "Types d'expertise", "messages", "fr");
t.add("app.type_expertise.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.type_expertise.admin.fields.piece.label", "Sur pi\u00e8ces", "messages", "fr");
t.add("app.type_expertise.admin.fields.site.label", "Sur site ?", "messages", "fr");
t.add("app.type_garantie.admin.menu", "Types de garantie", "messages", "fr");
t.add("app.type_garantie.admin.title", "Types de garantie", "messages", "fr");
t.add("app.type_garantie.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.type_note_frais.admin.fields.name.label", "Intitul\u00e9", "messages", "fr");
t.add("app.type_note_frais.admin.fields.type.label", "Type d'unit\u00e9 attendu", "messages", "fr");
t.add("app.type_note_frais.admin.menu", "Types de notes de frais", "messages", "fr");
t.add("app.type_note_frais.admin.title", "Types de notes de frais", "messages", "fr");
t.add("app.type_note_frais.admin.type.heure", "Heures\/Minutes", "messages", "fr");
t.add("app.type_note_frais.admin.type.heures", "Heures", "messages", "fr");
t.add("app.type_note_frais.admin.type.minutes", "Minutes", "messages", "fr");
t.add("app.type_note_frais.admin.type.quantite", "Quantit\u00e9", "messages", "fr");
t.add("app.type_note_frais.admin.type.euro", "\u20acuros", "messages", "fr");
t.add("app.type_note_frais.admin.type.jour", "Jour(s)", "messages", "fr");
t.add("app.type_procedure.admin.menu", "Proc\u00e9dures CDH", "messages", "fr");
t.add("app.type_procedure.admin.title", "Proc\u00e9dures CDH", "messages", "fr");
t.add("app.type_procedure.admin.fields.name.label", "Nom de proc\u00e9dure", "messages", "fr");
t.add("app.type_procedure.admin.fields.shortName.label", "Intitul\u00e9 court", "messages", "fr");
t.add("app.pdf.page", "Page [page] \/ [toPage]", "messages", "fr");
t.add("app.email.admin.lastSynchro.lastDate", "Derni\u00e8re copie faite le %date% \u00e0 %heure%", "messages", "fr");
t.add("app.email.admin.lastSynchro.not_yet", "Aucune copie n'a encore \u00e9t\u00e9 lanc\u00e9e", "messages", "fr");
t.add("app.email.admin.actions.synchro", "Copie des mails depuis Outlook", "messages", "fr");
t.add("app.email.admin.actions.in_trash", "Mettre \u00e0 la corbeille", "messages", "fr");
t.add("app.email.admin.actions.out_trash", "Remettre en boite de reception", "messages", "fr");
t.add("app.email.admin.flashes.synchro_success", "Copie de %nb%\/%total% email(s) avec succ\u00e8s", "messages", "fr");
t.add("app.email.admin.flashes.synchro_nothing_success", "Aucun email \u00e0 r\u00e9cup\u00e9rer", "messages", "fr");
t.add("app.email.admin.title", "Classement des emails", "messages", "fr");
t.add("app.email.admin.pieces_jointes", "Pi\u00e8ces jointes : ", "messages", "fr");
t.add("app.email.admin.mails_recus", "Mails re\u00e7us", "messages", "fr");
t.add("app.email.admin.action.open_trash", "Ouvrir la corbeille", "messages", "fr");
t.add("app.email.admin.action.in_trash", "Mettre \u00e0 la corbeille", "messages", "fr");
t.add("app.email.admin.action.boite_de_reception", "Ouvrir la boite de r\u00e9ception", "messages", "fr");
t.add("app.email.admin.trash", "Corbeille", "messages", "fr");
t.add("app.email.admin.fields.dossier.label", "Dossier", "messages", "fr");
t.add("app.email.admin.fields.dossier.placeholder", "S\u00e9lectionner un dossier", "messages", "fr");
t.add("app.email.admin.fields.sousDossier.label", "Sous-dossier", "messages", "fr");
t.add("app.email.admin.fields.sousDossier.placeholder", "S\u00e9lectionner un sous-dossier", "messages", "fr");
t.add("app.email.admin.fields.needIndicateur.label", "Indicateur ?", "messages", "fr");
t.add("app.email.admin.fields.indicateur.label", "Indicateur", "messages", "fr");
t.add("app.email.admin.fields.indicateur.placeholder", "S\u00e9lectionner un indicateur", "messages", "fr");
t.add("app.note_frais.admin.createdBy", "(cr\u00e9\u00e9e par %fullname%)", "messages", "fr");
t.add("app.note_de_frais_repartition.admin.fields.intervenant.label", "Intervenant", "messages", "fr");
t.add("app.note_de_frais_repartition.admin.fields.pourcentage.label", "Pourcentage", "messages", "fr");
t.add("app.note_de_frais_repartition.admin.error.dejaCloture", "Cette note est d\u00e9j\u00e0 clot\u00fbr\u00e9e, vous ne pouvez plus modifier les r\u00e9partitions", "messages", "fr");
t.add("siqual.admin.common.batch_action.archiver", "Archiver", "messages", "fr");
t.add("siqual.admin.common.batch_action.cloturer", "Cl\u00f4turer \/ R\u00e9activer", "messages", "fr");
t.add("siqual.admin.common.batch_action.download", "T\u00e9l\u00e9charger", "messages", "fr");
t.add("siqual.admin.common.batch_action.inTrash", "Mettre \u00e0 la corbeille", "messages", "fr");
t.add("siqual.admin.common.batch_action.move", "D\u00e9placer", "messages", "fr");
t.add("siqual.admin.common.batch_action.outTrash", "Remettre dans la bo\u00eete de r\u00e9ception", "messages", "fr");
t.add("siqual.user.admin.permission.role_app_dossier_archiver", "Archiver", "messages", "fr");
t.add("siqual.user.admin.permission.role_app_dossier_exporter_pieces_dossier", "Exporter les pi\u00e8ces", "messages", "fr");
t.add("siqual.user.admin.permission.role_app_note_frais_other_cloture", "Cloturer", "messages", "fr");
t.add("siqual.user.admin.permission.role_app_tableau_synoptique_show_all", "Voir Tout", "messages", "fr");
t.add("siqual.group.admin.fields.expert", "Expert ?", "messages", "fr");
t.add("siqual.group.admin.fields.secretariat", "Secr\u00e9tariat ?", "messages", "fr");
t.add("siqual.group.admin.fields.approbateur", "Approbateur ?", "messages", "fr");
t.add("project.config.outlook.title", "Connexion outlook", "messages", "fr");
t.add("project.config.outlook.login", "Identifiant", "messages", "fr");
t.add("project.config.outlook.passe", "Passe", "messages", "fr");
})(Translator);
