var displayAjaxFormErrors = function ($modalFormError, data) {
    var errors = data.errors;
    $modalFormError.find('.form-group.has-error').removeClass('has-error');
    $modalFormError.find('.control-group.has-error').parent().find('.help-block').remove();
    $modalFormError.find('.control-group.has-error').removeClass('has-error');
    displayFieldError($modalFormError, errors)
};

var displayFieldError = function ($modalFormError, errors) {
    for (var fieldName in errors) {
        if (Array.isArray(errors[fieldName])) {
            var $elmt = $modalFormError.find('[name*="[' + fieldName + ']"]');
            var $controls = $elmt.closest('.control-group');
            $controls.addClass('has-error');
            $controls.parent().find('.help-block').remove();
            var $formGroup = $elmt.closest('.form-group');
            $formGroup.addClass('has-error');
            $controls.after('<p class="help-block">' + errors[fieldName] + '</p>');
        } else {
            displayFieldError($modalFormError, errors[fieldName])
        }
    }
};

var treatListenFormAdd = function ($collectionHolder, indexName, append) {
    if (indexName === undefined || indexName == '') {
        indexName = '__name__';
    }
    if (append === undefined) {
        append = true;
    }
    var prototype = $collectionHolder.attr('data-prototype');
    var regex = new RegExp(indexName, "g");
    var indexValue = 0;
    if ($collectionHolder.children('[data-type="' + $collectionHolder.data('type') + '"]').length) {
        var maxIndex = 0;
        $.each($collectionHolder.children('[data-type="' + $collectionHolder.data('type') + '"]'), function(k, v) {
            var i = parseInt($(v).attr('data-index'));
            if (i > maxIndex) {
                maxIndex = i;
            }
        });

        indexValue = maxIndex + 1;
    }
    var $newForm = prototype.replace(regex, indexValue);

    if (append) {
        $collectionHolder.append($newForm);
    } else {
        $collectionHolder.prepend($newForm);
    }
    $collectionHolder.find('[data-index="' + indexValue + '"]').addClass('new_collection_entry');
    if (append) {
        $newForm = $collectionHolder.children('[data-type="' + $collectionHolder.data('type') + '"]:last-child');
    } else {
        $newForm = $collectionHolder.children('[data-type="' + $collectionHolder.data('type') + '"]:first-child');
    }
    $newForm.attr('data-index', indexValue);


    initFormTypes($newForm);

    $(document).trigger('collection_added');
    $(document).trigger('collection_added_'+$collectionHolder.data('type'));
    return indexValue;
};

var listenFormAdd = function ($collectionHolder, indexName, append) {
    return treatListenFormAdd($collectionHolder, indexName, append);
};

var triggerFormDelete = function (id) {
    $(document).delegate('.' + id, 'click', function (e) {
        e.preventDefault();

        $(document).delegate('#siqual-modal-delete-form', 'submit', function (e) {
            e.preventDefault();
            if ($(this).attr('action').substr(0, 1) == '/') {
                $(document).trigger('collection_removed');
                var $form = $('<form action="' + $(this).attr('action') + '" method="post" class="hide"></form>');
                $('body').append($form);
                $form.submit();
            } else {
                var $boxToDelete = $('#' + $(this).attr('action'));
                if ($boxToDelete.length) {
                    $boxToDelete.remove();
                }
                $('#siqual-modal-delete').modal('hide');
                $(document).trigger('collection_removed');
                return false;
            }

        });
    });
};

var startGeneralLoading = function () {
    var $generalOverlay = $('#general_overlay');
    $generalOverlay.find('h4').css('opacity', '0');
    $generalOverlay.addClass('open');
};
var stopGeneralLoading = function () {
    $('#general_overlay').removeClass('open');
};

$(document).ready(function () {
    $(document).delegate('.add_collection', 'click', function (e) {
        e.preventDefault();
        var $collectionHolder = $(this.parentElement).find('[data-type="' + $(this).data('type') + '"][data-prototype]');
        var indexName = $(this).data('index-name');
        if (indexName === undefined || indexName == '') {
            indexName = '__name__';
        }
        var append = $(this).attr('data-append');
        if (append === undefined || append == "") {
            listenFormAdd($collectionHolder, indexName);
        } else {
            listenFormAdd($collectionHolder, indexName, $(this).data('append'));
        }
    });

    triggerFormDelete('siqual-modal-delete-trigger');
});