!function ($) {
    'use strict';
    $(document).ready(function () {
        $(document).delegate('#siqual-modal-delete-form-submit', 'click', function(e) {
            e.preventDefault();
            $('#siqual-modal-delete-form').submit();
        });
        $(document).delegate('.siqual-modal-delete-trigger', 'click', function (e) {
            e.preventDefault();
            var $form = $('#siqual-modal-delete-form');
            var $formSubmit = $('#siqual-modal-delete-form-submit');
            var $button = $("#siqual-modal-delete-button");
            if ($(this).data('modal-event') == "click") {
                $form.addClass('hidden');
                $formSubmit.addClass('hidden');
                $button.show();
                $button.removeClass($button.data('modal-action'));
                $button.data($(this).data());
                $button.data('modal-action', $(this).data('modal-action'));
                $button.addClass($(this).data('modal-action'));
            } else {
                $form.removeClass('hidden');
                $formSubmit.removeClass('hidden');
                $button.hide();
                $form.attr('action', $(this).data('modal-action'));
            }
            $('#siqual-modal-delete').modal('show');
        });
        $(document).delegate('#siqual-modal-delete', 'hidden', function () {
            $('#siqual-modal-delete-form').attr('action', '');
            $(this).find('.siqual-spinner i').attr('class', 'icon-trash');
        });
        $(document).delegate('.siqual-modal-confirm-trigger', 'click', function (e) {
            e.preventDefault();
            $('#siqual-modal-confirm-link').attr('href', $(this).data('modal-action'));
            var $label = $("#siqual-modal-confirm-label");
            var $body = $('#siqual-modal-confirm-body');
            if ($(this).attr('data-modal-title') !== undefined && $(this).attr('data-modal-title') !== false) {
                $label.html($(this).data('modal-title'));
            } else {
                $label.html($label.data('modal-title'));
            }
            if ($(this).attr('data-modal-body') !== undefined && $(this).attr('data-modal-body') !== false) {
                $body.html($(this).data('modal-body'));
            } else {
                $body.html($body.data('modal-body'));
            }
            $('#siqual-modal-confirm').modal('show');
        });
        $('.siqual-form-modal-confirm-trigger-button').each(function () {
            $(this).closest('form').addClass('siqual-form-modal-confirm-trigger');
        });
        $(document).delegate('.siqual-form-modal-confirm-trigger', 'submit', function (e) {
            if ($(this).attr('data-disable-modal-confirm') === undefined) { // to avoid form submit loops with the modal submit
                e.preventDefault(); // stops the submit process
                var uniqId = 'siqual-form-modal';
                $(this).attr('data-form-id', uniqId); // affects the id to the current form so we can find it when we'll submit from the modal
                $('#siqual-modal-confirm-link').attr('data-form-id', uniqId).attr('href', '#');
                var $label = $("#siqual-modal-confirm-label");
                var $body = $('#siqual-modal-confirm-body');
                if ($(this).attr('data-modal-title') !== undefined && $(this).attr('data-modal-title') !== false) {
                    $label.html($(this).data('modal-title'));
                } else {
                    $label.html($label.data('modal-title'));
                }
                if ($(this).attr('data-modal-body') !== undefined && $(this).attr('data-modal-body') !== false) {
                    $body.html($(this).data('modal-body'));
                } else {
                    $body.html($body.data('modal-body'));
                }
                $('#siqual-modal-confirm').modal('show');
            }
        });
        $(document).delegate('#siqual-modal-confirm-link', 'click', function (e) {
            if ($(this).attr('data-form-id') !== undefined) { // checks if we are using a simple modal confirm
                e.preventDefault();
                var form = $('form[data-form-id="' + $(this).attr('data-form-id') + '"]');
                form.attr('data-disable-modal-confirm', true);
                form.submit();
            }
            setTimeout(function () {
                $('#siqual-modal-confirm').modal('hide')
            }, 1500);
        });
        $(document).delegate('#siqual-modal-confirm', 'hidden', function () {
            $(this).find('.siqual-spinner i').attr('class', 'icon-ok');
            var confirmLink = $('#siqual-modal-confirm-link');
            confirmLink.attr('href', '');
            $('#siqual-modal-confirm-label').html();
            if (confirmLink.attr('data-form-id') !== undefined && confirmLink.attr('data-form-id') !== false) { // checks if we are using a simple modal confirm
                var form = $('form[data-form-id="' + confirmLink.attr('data-form-id') + '"]');
                form.removeAttr('data-form-id');
            }
        });
    });
}(jQuery);